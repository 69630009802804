function Proud() {
    return (
        <>
            <svg id="proud-anim" width="369" height="348" viewBox="0 0 369 348" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="proud" clip-path="url(#clip0_205_256)">
                    <g id="biroo">
                        <path id="Vector" d="M267.896 197.476V293.609L283.951 319.814L285.173 321.808H348.523L349.803 197.476H267.896Z" fill="#E6E6E6" />
                        <path id="Vector_2" opacity="0.1" d="M267.896 197.476V293.609L283.951 319.814L284.936 197.476H267.896Z" fill="black" />
                        <path id="Vector_3" d="M114.959 194.912V291.045L98.9042 317.251L97.6823 319.244H34.3322L33.0522 194.912H114.959Z" fill="#E6E6E6" />
                        <path id="Vector_4" opacity="0.1" d="M114.96 194.912V291.045L98.9042 317.251L97.9189 194.912H114.96Z" fill="black" />
                        <path id="Vector_5" d="M369 192.99V198.758H24.7336V190.426L56.7285 173.763H340.845L369 192.99Z" fill="#E6E6E6" />
                        <path id="Vector_6" opacity="0.1" d="M369 192.99V198.758H24.7336V190.426L369 192.99Z" fill="black" />
                        <path id="Vector_7" d="M74.0062 221.829H58.6482L52.2493 217.343H81.0449L74.0062 221.829Z" fill="#3F3D56" />
                        <path id="Vector_8" d="M74.0062 240.415H58.6482L52.2493 235.929H81.0449L74.0062 240.415Z" fill="#3F3D56" />
                        <path id="Vector_9" d="M74.0062 266.692H58.6482L52.2493 262.205H81.0449L74.0062 266.692Z" fill="#3F3D56" />
                        <path id="Vector_10" d="M74.0062 292.968H58.6482L52.2493 288.482H81.0449L74.0062 292.968Z" fill="#3F3D56" />
                        <path id="Vector_11" d="M308.849 224.393H324.207L330.606 219.907H301.81L308.849 224.393Z" fill="#3F3D56" />
                        <path id="Vector_12" d="M308.849 242.979H324.207L330.606 238.492H301.81L308.849 242.979Z" fill="#3F3D56" />
                        <path id="Vector_13" d="M308.849 269.255H324.207L330.606 264.769H301.81L308.849 269.255Z" fill="#3F3D56" />
                        <path id="Vector_14" d="M308.849 295.531H324.207L330.606 291.045H301.81L308.849 295.531Z" fill="#3F3D56" />
                        <path id="Vector_15" d="M276.236 183.05C277.415 183.05 278.37 182.755 278.37 182.392C278.37 182.029 277.415 181.734 276.236 181.734C275.057 181.734 274.101 182.029 274.101 182.392C274.101 182.755 275.057 183.05 276.236 183.05Z" fill="#F2F2F2" />
                        <path id="Vector_16" d="M49.1151 0.000139464C48.2186 -0.07358 47.3209 0.127267 46.5409 0.576066C45.7751 1.10133 45.2924 2.02153 44.7997 2.87783C42.916 6.19092 40.3657 9.07607 37.3105 11.3505C35.0852 12.9833 32.256 15.0191 32.6368 18.0294C32.8376 19.0236 33.2043 19.9767 33.7215 20.8488C35.7743 24.9992 41.3763 28.2066 40.8253 32.9426C42.8649 29.5228 40.1368 27.8319 42.1764 24.4121C43.1477 22.7835 44.8351 20.9452 46.3015 21.9679C46.7926 22.3104 47.115 22.9246 47.6263 23.2251C48.8462 23.942 50.144 22.5713 51.2239 21.6005C54.9476 18.2526 60.2431 19.1296 64.8769 20.1728C67.0644 20.6652 69.4673 21.3113 70.7605 23.4053C72.4607 26.1586 69.1451 29.1321 68.3073 32.2448C68.2389 32.5101 68.2357 32.7879 68.2979 33.0547C68.3601 33.3215 68.4859 33.5692 68.6645 33.7766C68.8431 33.9841 69.0693 34.1451 69.3236 34.246C69.5779 34.3468 69.8528 34.3845 70.1248 34.3557C71.5152 34.2184 73.1623 34.1058 73.2445 33.4947C74.9939 33.5571 77.1373 33.3586 77.8868 31.5343C78.1159 30.8125 78.237 30.0607 78.2461 29.3034C78.5212 26.2389 79.8199 23.4432 80.6802 20.5265C81.5405 17.6099 81.9145 14.1917 80.4627 11.6058C79.9446 10.7454 79.3038 9.96543 78.5606 9.29048C70.3218 1.30517 59.4895 -0.0117137 49.1151 0.000139464Z" fill="#2F2E41" />
                        <path id="Vector_17" d="M42.6171 38.9245C42.6171 38.9245 44.4088 53.8792 37.8389 55.0756C31.269 56.272 43.8116 76.6103 43.8116 76.6103L73.6749 80.1994L66.5077 56.2719C66.5077 56.2719 61.7296 54.4774 64.716 42.5136C67.7023 30.5499 42.6171 38.9245 42.6171 38.9245Z" fill="#FFB8B8" />
                        <path id="Vector_18" d="M24.4004 295.846L26.7897 328.746H39.3319L35.7487 295.846H24.4004Z" fill="#FFB8B8" />
                        <path id="Vector_19" d="M116.38 233.634L118.171 261.151L131.909 255.169L126.533 232.438L116.38 233.634Z" fill="#FFB8B8" />
                        <path id="Vector_20" d="M15.4414 170.227C15.4414 170.227 16.0387 204.921 19.6223 216.287C23.2059 227.652 22.6086 228.251 22.0114 230.045C21.4141 231.84 20.8168 230.643 20.8168 233.634C20.8168 236.625 19.6223 283.882 20.8168 288.069C22.0114 292.257 17.2332 299.435 20.8168 300.033C24.4004 300.631 41.1239 300.033 41.7212 296.444C42.3185 292.855 36.9431 291.658 39.3321 289.266C41.7212 286.873 45.3048 237.822 45.3048 237.822L53.6665 203.725L69.1955 185.779H102.045L112.199 225.26C112.199 225.26 108.018 236.625 111.004 236.625C113.991 236.625 131.909 240.214 131.909 227.054C131.909 213.894 125.936 175.012 124.741 173.816C123.547 172.619 125.339 168.432 123.547 166.637C121.755 164.843 100.851 152.281 93.0862 149.888C85.3217 147.495 74.4295 144.648 74.4295 144.648L15.4414 170.227Z" fill="#2F2E41" />
                        <path id="Vector_21" d="M131.909 250.384C131.909 250.384 122.95 247.393 118.769 256.365C114.588 265.338 116.38 267.731 116.38 267.731C116.38 267.731 130.117 272.516 132.506 270.124C133.545 269.083 136.846 268.721 140.243 268.645C145.418 268.528 146.795 261.196 141.908 259.489C141.762 259.438 141.614 259.394 141.465 259.356C136.687 258.16 131.909 250.384 131.909 250.384Z" fill="#2F2E41" />
                        <path id="Vector_22" d="M56.0557 48.7945C65.9516 48.7945 73.9737 40.76 73.9737 30.8489C73.9737 20.9378 65.9516 12.9033 56.0557 12.9033C46.1599 12.9033 38.1377 20.9378 38.1377 30.8489C38.1377 40.76 46.1599 48.7945 56.0557 48.7945Z" fill="#FFB8B8" />
                        <path id="Vector_23" d="M32.7622 62.5528C32.7622 62.5528 62.0284 68.5347 69.1956 58.9637C76.3628 49.3927 79.3491 85.8821 79.3491 85.8821L82.9327 124.166L77.5573 145.103C77.5573 145.103 49.4857 167.834 40.5267 169.628C31.5677 171.423 17.8306 172.619 17.8306 172.619C17.8306 172.619 22.0115 106.22 22.0115 105.024C22.0115 103.828 32.7622 62.5528 32.7622 62.5528Z" fill="#6C63FF" />
                        <path id="Vector_24" d="M41.6628 51.334C41.6628 51.334 35.1513 47.5981 33.3595 51.1873C31.5677 54.7764 14.8442 63.7492 13.0524 63.7492C11.2606 63.7492 16.636 113.399 14.247 119.381C11.8579 125.362 0.509803 169.03 7.07974 172.619C13.6497 176.208 8.87155 169.03 15.4415 178.003C22.0114 186.976 54.2639 186.976 56.0557 183.387C57.8475 179.797 41.7212 152.879 44.7076 127.755C47.6939 102.631 52.4721 67.9365 48.2912 63.151C44.1103 58.3655 41.6628 51.334 41.6628 51.334Z" fill="#2F2E41" />
                        <path id="Vector_25" d="M63.2229 55.3746L64.0548 52.5072C64.0548 52.5072 88.9054 60.7583 90.0999 66.142C91.2945 71.5257 90.6972 109.211 86.5163 112.202C82.3355 115.193 76.3628 119.979 80.5437 127.157C84.7245 134.335 89.5026 142.112 93.0863 142.71C96.6699 143.308 103.24 147.495 101.448 151.683C99.6562 155.87 78.1546 148.094 78.1546 148.094C78.1546 148.094 63.8202 137.326 64.4174 120.577C65.0147 103.828 63.2229 55.3746 63.2229 55.3746Z" fill="#2F2E41" />
                        <path id="Vector_26" d="M84.1272 125.961L69.7928 152.879C69.7928 152.879 48.8885 174.414 60.8338 176.807C72.7792 179.199 79.3491 157.066 79.3491 157.066L94.8781 134.934L84.1272 125.961Z" fill="#FFB8B8" />
                        <path id="Vector_27" d="M49.9879 4.69559C49.2657 4.64199 48.5423 4.78252 47.8925 5.10265C47.3049 5.53243 46.8206 6.08826 46.4751 6.72952C44.882 9.12955 42.8054 11.1694 40.3785 12.718C38.567 13.872 36.264 15.3109 36.5739 17.4386C36.7422 18.1519 37.0416 18.8277 37.4569 19.4313C39.1338 22.3944 39.8014 25.824 39.3586 29.201L44.3396 21.9498C45.1303 20.7988 46.5039 19.4994 47.6976 20.2223C48.0973 20.4643 48.3598 20.8985 48.776 21.1108C49.7691 21.6176 50.8256 20.6488 51.7046 19.9626C54.7359 17.5963 59.0466 18.2162 62.8187 18.9535C64.5995 19.3015 66.5555 19.7582 67.6082 21.2382C69.3576 23.6978 67.5309 27.2517 68.5519 30.0931C68.8377 29.8927 69.0804 29.6368 69.2657 29.3407C69.4509 29.0446 69.5749 28.7143 69.6303 28.3693C71.0544 28.4134 72.7992 28.2732 73.4094 26.9837C73.5949 26.4784 73.6938 25.9453 73.7019 25.4069C73.9258 23.241 74.9829 21.265 75.6833 19.2035C76.3836 17.142 76.6881 14.7261 75.5062 12.8984C75.0739 12.2791 74.5521 11.7276 73.9579 11.2619C67.2511 5.61796 58.4332 4.68721 49.9879 4.69559Z" fill="#2F2E41" />
                        <path id="Vector_28" d="M84.1273 63.7493L89.6921 65.2286C89.6921 65.2286 102.643 98.4441 99.6562 121.175C96.6699 143.906 94.8781 139.121 94.8781 139.121C94.8781 139.121 90.0999 128.353 79.3491 130.746L84.1273 63.7493Z" fill="#2F2E41" />
                        <path id="Vector_29" d="M25.2279 324.398C23.7876 326.358 24.0764 329.16 22.8006 331.232C21.6947 333.027 19.6105 333.969 18.2272 335.56C17.7858 336.088 17.3924 336.655 17.0514 337.254C15.7683 339.406 14.7253 342.165 15.989 344.329C17.0057 346.07 19.1425 346.751 21.1023 347.215C23.5793 347.801 26.153 348.27 28.6586 347.822C31.1642 347.374 33.613 345.837 34.5064 343.45C34.682 342.85 34.8907 342.26 35.1317 341.683C36.4897 339.005 40.7495 338.977 42.1394 336.315C43.1121 334.453 42.2179 332.202 41.3242 330.3L38.5945 324.492C37.6886 322.564 34.0163 323.673 32.1019 323.211C29.6051 322.608 27.0241 321.945 25.2279 324.398Z" fill="#2F2E41" />
                        <path id="Vector_30" d="M5.28809 137.924L30.3733 176.807C30.3733 176.807 45.9023 196.547 50.6804 187.574C55.4585 178.601 33.9569 163.647 33.9569 163.647L17.8307 133.737L5.28809 137.924Z" fill="#FFB8B8" />
                        <path id="Vector_31" d="M17.8306 64.9456L13.0524 63.7493C13.0524 63.7493 3.49615 66.7402 1.10708 79.3021C-1.28198 91.864 0.509813 142.112 3.49615 142.71C6.48248 143.308 18.2456 133.961 20.1285 135.943C22.0114 137.924 16.0388 127.755 17.8305 122.372C19.6223 116.988 17.8306 64.9456 17.8306 64.9456Z" fill="#2F2E41" />
                    </g>
                    <g id="PC">
                        <path id="Vector_32" d="M319.307 62.595H190.018C189.375 62.5949 188.74 62.7215 188.146 62.9676C187.553 63.2136 187.014 63.5744 186.56 64.0291C186.106 64.4839 185.746 65.0238 185.5 65.6179C185.254 66.2121 185.128 66.849 185.128 67.4921V154.719C185.128 155.362 185.254 155.999 185.5 156.593C185.746 157.188 186.106 157.727 186.56 158.182C187.014 158.637 187.553 158.998 188.146 159.244C188.74 159.49 189.375 159.616 190.018 159.616H239.097L237.258 171.374C237.258 171.374 226.75 176.965 234.139 177.13C241.527 177.294 276.336 177.13 276.336 177.13C276.336 177.13 283.067 177.13 272.395 171.21L270.665 159.616H319.307C319.95 159.616 320.585 159.49 321.179 159.244C321.772 158.998 322.311 158.637 322.765 158.182C323.219 157.727 323.579 157.188 323.825 156.593C324.071 155.999 324.197 155.362 324.197 154.719V67.4921C324.197 66.849 324.071 66.2121 323.825 65.6179C323.579 65.0238 323.219 64.4839 322.765 64.0291C322.311 63.5744 321.772 63.2136 321.179 62.9676C320.585 62.7215 319.95 62.5949 319.307 62.595Z" fill="#3F3D56" />
                        <path id="Vector_33" d="M319.518 67.7749H189.808V140.459H319.518V67.7749Z" fill="#F2F2F2" />
                        <path id="Vector_34" d="M254.663 66.2949C255.116 66.2949 255.484 65.9268 255.484 65.4727C255.484 65.0186 255.116 64.6505 254.663 64.6505C254.209 64.6505 253.842 65.0186 253.842 65.4727C253.842 65.9268 254.209 66.2949 254.663 66.2949Z" fill="#F2F2F2" />
                        <path id="Vector_35" d="M254.663 156.081C256.385 156.081 257.782 154.682 257.782 152.956C257.782 151.231 256.385 149.832 254.663 149.832C252.94 149.832 251.543 151.231 251.543 152.956C251.543 154.682 252.94 156.081 254.663 156.081Z" fill="#F2F2F2" />
                        <path id="Vector_36" opacity="0.1" d="M249.802 186.009V187.654H161.467V186.338L161.589 186.009H249.802Z" fill="black" />
                        <g id="Group 13">
                            <path id="Rectangle 55" d="M242.386 91.6467H226.949V95.3688H242.386V91.6467Z" fill="#6C63FF" />
                            <path id="Rectangle 56" d="M295.844 91.6467H290.412V95.3688H295.844V91.6467Z" fill="#6C63FF" />
                            <path id="Rectangle 57" d="M306.707 91.6467H301.275V95.3688H306.707V91.6467Z" fill="#6C63FF" />
                            <path id="Rectangle 58" d="M284.695 91.6467H247.817V95.3688H284.695V91.6467Z" fill="#6C63FF" />
                            <path id="Rectangle 59" d="M222.947 115.697H207.51V119.419H222.947V115.697Z" fill="#6C63FF" />
                            <path id="Rectangle 60" d="M276.404 115.697H270.973V119.419H276.404V115.697Z" fill="#6C63FF" />
                            <path id="Rectangle 61" d="M287.267 115.697H281.836V119.419H287.267V115.697Z" fill="#6C63FF" />
                            <path id="Rectangle 62" d="M265.255 115.697H228.378V119.419H265.255V115.697Z" fill="#6C63FF" />
                            <path id="Rectangle 63" d="M286.696 99.9497H271.259V103.672H286.696V99.9497Z" fill="#6C63FF" />
                            <path id="Rectangle 64" d="M307.564 99.9497H292.127V103.672H307.564V99.9497Z" fill="#6C63FF" />
                            <path id="Rectangle 66" d="M212.941 99.9497H207.51V103.672H212.941V99.9497Z" fill="#6C63FF" />
                            <path id="Rectangle 67" d="M223.804 99.9497H218.373V103.672H223.804V99.9497Z" fill="#6C63FF" />
                            <path id="Rectangle 68" d="M266.113 99.9497H229.236V103.672H266.113V99.9497Z" fill="#6C63FF" />
                            <path id="Rectangle 69" d="M254.678 107.966H239.241V111.688H254.678V107.966Z" fill="#6C63FF" />
                            <path id="Rectangle 70" d="M233.81 107.966H218.373V111.688H233.81V107.966Z" fill="#6C63FF" />
                            <path id="Rectangle 71" d="M212.941 107.966H207.51V111.688H212.941V107.966Z" fill="#6C63FF" />
                            <path id="Rectangle 73" d="M307.564 107.966H302.133V111.688H307.564V107.966Z" fill="#6C63FF" />
                            <path id="Rectangle 74" d="M296.701 107.966H259.824V111.688H296.701V107.966Z" fill="#6C63FF" />
                        </g>
                        <path id="Vector_37" d="M249.802 186.009V187.654H161.467V186.338L161.589 186.009L163.766 180.09H247.995L249.802 186.009Z" fill="#3F3D56" />
                        <path id="Vector_38" d="M283.795 184.4C283.635 185.089 283.027 185.815 281.654 186.503C276.729 188.969 266.713 185.845 266.713 185.845C266.713 185.845 258.996 184.529 258.996 181.076C259.213 180.928 259.439 180.793 259.673 180.673C261.743 179.576 268.61 176.868 280.785 180.788C281.682 181.07 282.483 181.596 283.099 182.307C283.372 182.576 283.578 182.906 283.699 183.269C283.82 183.633 283.853 184.02 283.795 184.4Z" fill="#3F3D56" />
                        <path id="Vector_39" opacity="0.1" d="M283.795 184.4C277.766 186.713 272.392 186.886 266.877 183.049C264.792 181.505 262.266 180.672 259.673 180.673C261.744 179.576 268.61 176.868 280.785 180.788C281.682 181.07 282.483 181.596 283.099 182.307C283.372 182.576 283.578 182.906 283.699 183.269C283.82 183.633 283.853 184.02 283.795 184.4Z" fill="black" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_205_256">
                        <rect width="369" height="348" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>

    );
}

export default Proud;
