function Relax() {
    return (
        <>

            <svg width="666" height="448" viewBox="0 0 666 448" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="undraw_relaxation_re_ohkx 1" clip-path="url(#clip0_205_386)">
                    <path id="Vector" d="M82.1761 253.295C109.171 253.295 131.054 231.403 131.054 204.398C131.054 177.392 109.171 155.5 82.1761 155.5C55.1814 155.5 33.2979 177.392 33.2979 204.398C33.2979 231.403 55.1814 253.295 82.1761 253.295Z" fill="#FF6884" />
                    <path id="Vector_2" d="M103.357 446.609H9.23438L30.5057 105.082L44.3872 24.5897L47.0329 11.0093C50.1656 -5.07049 73.7999 -2.92312 73.9867 13.4582C74.421 51.5438 58.4677 87.3866 59.2462 105.082C62.7579 185.19 70.0971 298.782 84.2418 332.265C94.7468 357.142 100.364 437.251 103.357 446.609Z" fill="#3F3D56" />
                    <path id="Vector_3" d="M653.801 446.609H566.783L557.639 105.082L540.107 24.6045C539.905 8.18436 563.563 5.52498 567.009 21.5805C567.009 21.5805 584.281 57.3958 586.379 105.082C589.891 185.19 620.542 298.782 634.686 332.265C645.191 357.142 650.809 437.251 653.801 446.609Z" fill="#3F3D56" />
                    <path id="Vector_4" d="M125.976 224.315C125.84 224.315 125.703 224.279 125.58 224.202L27.6696 163.404C27.3167 163.185 27.208 162.721 27.4272 162.368C27.6461 162.014 28.1102 161.905 28.4626 162.126L126.373 222.924C126.726 223.143 126.835 223.607 126.615 223.96C126.473 224.189 126.227 224.315 125.976 224.315Z" fill="#3F3D56" />
                    <path id="Vector_5" d="M512.491 246.131C512.22 246.131 511.958 245.984 511.824 245.727C511.632 245.359 511.774 244.904 512.142 244.711L596.846 200.457C597.215 200.264 597.668 200.407 597.86 200.775C598.053 201.143 597.91 201.598 597.542 201.791L512.838 246.045C512.727 246.104 512.608 246.131 512.491 246.131Z" fill="#3F3D56" />
                    <path id="Vector_6" d="M0 447.105C0 447.601 0.398568 448 0.894893 448H120.677C121.173 448 121.572 447.601 121.572 447.105C121.572 446.608 121.173 446.209 120.677 446.209H0.894893C0.398568 446.209 0 446.608 0 447.105Z" fill="#3F3D56" />
                    <path id="Vector_7" d="M536.157 447.105C536.157 447.601 536.556 448 537.052 448H665.106C665.602 448 666 447.601 666 447.105C666 446.608 665.602 446.209 665.106 446.209H537.052C536.556 446.209 536.157 446.608 536.157 447.105Z" fill="#3F3D56" />
                    <path id="Vector_8" d="M297.029 447.105C297.029 447.601 297.428 448 297.924 448H425.978C426.474 448 426.873 447.601 426.873 447.105C426.873 446.608 426.474 446.209 425.978 446.209H297.924C297.428 446.209 297.029 446.608 297.029 447.105Z" fill="#3F3D56" />
                    <g id="hooman-hammock">
                        <path id="Vector_9" d="M131.992 222.811C151.678 224.394 197.414 248.388 197.414 248.388C232.954 283.985 286.037 286.096 321.489 287.505C374.201 289.602 414.105 273.188 443.309 261.176C443.309 261.176 511.581 245.446 512.49 245.379C513.578 245.298 514.609 247.981 514.746 248.388C517 255.052 492.226 279.738 463.612 288.258C459.714 289.418 450.871 291.73 441.805 298.789C436.177 303.172 432.871 307.51 432.029 308.569C424.352 318.236 383.55 355.062 337.281 356.714C297.71 358.126 266.078 333.386 230.501 305.56C216.39 294.523 218.082 293.708 200.422 279.983C160.37 248.855 140.344 233.291 131.24 228.077C129.978 227.354 125.56 224.884 125.976 223.563C126.356 222.358 130.58 222.697 131.992 222.811Z" fill="#6C63FF" />
                        <path id="Vector_10" d="M127.104 223.187C146.791 224.77 192.179 257.749 192.179 257.749C227.719 293.346 280.803 295.457 316.255 296.867C368.966 298.963 408.87 282.55 438.074 270.538C438.074 270.538 498.582 280.382 505.752 256.997C505.82 256.774 506.347 254.808 507.256 254.74C508.344 254.66 509.374 257.343 509.512 257.749C511.765 264.413 486.992 289.1 458.377 297.619C454.479 298.78 445.636 301.092 436.57 308.151C430.942 312.534 427.636 316.871 426.795 317.93C419.117 327.598 378.315 364.424 332.046 366.075C292.475 367.488 260.843 342.748 225.266 314.921C211.155 303.885 212.847 303.07 195.187 289.345C155.135 258.216 135.11 242.652 126.005 237.438C124.744 236.716 122.928 223.755 123.344 222.435C123.725 221.229 125.693 223.073 127.104 223.187Z" fill="#6C63FF" />
                        <g id="Group">
                            <path id="Vector_11" d="M385.179 188.138C385.179 188.138 379.336 176.449 380.505 165.928L361.06 176.011L361.809 195.152L385.179 188.138Z" fill="#A0616A" />
                            <path id="Vector_12" d="M368.003 181.41C377.69 181.41 385.544 173.553 385.544 163.862C385.544 154.171 377.69 146.314 368.003 146.314C358.315 146.314 350.462 154.171 350.462 163.862C350.462 173.553 358.315 181.41 368.003 181.41Z" fill="#A0616A" />
                            <g id="Group_2">
                                <path id="Vector_13" d="M391.029 439.428L380.325 439.427L375.233 398.125L391.031 398.126L391.029 439.428Z" fill="#A0616A" />
                                <path id="Vector_14" d="M354.357 439.186C354.024 439.747 353.848 441.559 353.848 442.212C353.848 444.22 355.474 445.847 357.481 445.847H390.629C391.998 445.847 393.107 444.737 393.107 443.367V441.987C393.107 441.987 394.747 437.837 391.371 432.723C391.371 432.723 387.175 436.728 380.905 430.455L379.056 427.104L365.672 436.897L358.254 437.81C356.631 438.01 355.192 437.779 354.357 439.186Z" fill="#2F2E41" />
                            </g>
                            <path id="Vector_15" d="M359.472 188.138L385.179 184.631L405.043 193.983L406.212 266.458C406.212 266.458 357.346 272.303 349.645 264.705C341.944 257.106 347.787 196.321 347.787 196.321L359.472 188.138Z" fill="#E6E6E6" />
                            <path id="Vector_16" d="M372.39 168.333H377.106C377.106 168.333 381.197 163.294 380.647 166.364C380.096 169.434 382.298 172.738 382.298 172.738L385.601 171.637C385.601 171.637 392.206 143.853 372.39 142.326C352.574 140.8 348.171 156.218 348.171 156.218C348.171 156.218 349.272 163.928 351.474 161.725C353.675 159.522 365.785 158.421 365.785 158.421L372.39 168.333Z" fill="#2F2E41" />
                            <g id="Group_3">
                                <path id="Vector_17" d="M347.57 357.655L337.781 361.985L316.424 326.273L330.872 319.881L347.57 357.655Z" fill="#A0616A" />
                                <path id="Vector_18" d="M313.934 372.272C313.856 372.921 314.428 374.649 314.692 375.247C315.504 377.082 317.649 377.912 319.484 377.1L349.799 363.686C351.051 363.133 351.617 361.668 351.064 360.416L350.505 359.153C350.505 359.153 350.327 354.695 345.172 351.384C345.172 351.384 342.953 356.744 334.683 353.545L331.637 351.229L323.357 365.6L316.942 369.437C315.538 370.277 314.129 370.648 313.934 372.272Z" fill="#2F2E41" />
                            </g>
                            <path id="Vector_19" d="M402.707 259.444L406.212 270.048V306.497L394.762 339.518L395.695 416.508H375.15L372.831 337.976L374.662 299.189L360.64 293.344L323.809 295.618L341.033 337.976L322.08 350.623C322.08 350.623 293.732 285.994 297.974 279.733C302.215 273.472 351.877 262.427 351.877 262.427C351.877 262.427 402.707 260.613 402.707 259.444Z" fill="#2F2E41" />
                            <g id="Group_4">
                                <path id="Vector_20" d="M413.223 217.362L418.872 226.618C418.872 226.618 426.859 258.02 418.872 258.147C410.886 258.275 394.762 242.145 394.762 242.145L396.864 233.727L402.707 236.065L401.538 217.362H413.223Z" fill="#A0616A" />
                                <path id="Vector_21" d="M395.695 193.836C395.695 193.836 414.391 190.541 416.144 202.782C417.897 215.024 418.872 226.618 418.872 226.618L398.617 228.297L395.695 193.836Z" fill="#E6E6E6" />
                                <path id="Vector_22" d="M399.754 241.115C401.444 238.741 398.966 234.076 394.22 230.694C389.474 227.312 384.256 226.495 382.566 228.869C380.876 231.243 383.353 235.909 388.1 239.291C392.846 242.673 398.063 243.489 399.754 241.115Z" fill="#A0616A" />
                            </g>
                            <g id="Group_5">
                                <path id="Vector_23" d="M370.499 213.123L370.456 212.642C370.424 212.26 370.253 211.915 369.986 211.681C369.719 211.447 369.377 211.36 369.046 211.422L352.98 214.947C352.649 215.021 352.371 215.243 352.19 215.564C352.019 215.896 351.965 216.291 352.051 216.661L352.2 217.302L353.269 221.899L357.392 239.649C357.541 240.29 358.065 240.709 358.62 240.659C358.674 240.659 358.717 240.659 358.77 240.647L371.471 237.763C371.599 237.738 371.717 237.689 371.824 237.615C372.23 237.356 372.475 236.838 372.432 236.284L370.499 213.123Z" fill="#6C63FF" />
                                <path id="Vector_24" d="M370.188 210.447C369.958 210.453 369.651 210.465 369.283 210.483C369.357 210.26 369.369 210.006 369.299 209.758L368.814 208.038C368.688 207.591 368.329 207.288 367.922 207.285C365.497 207.271 357.186 207.488 352.324 210.956C352.02 211.173 351.853 211.575 351.888 211.986L352.051 213.875C352.071 214.108 352.153 214.315 352.276 214.478C351.861 214.675 351.449 214.88 351.042 215.096C350.787 215.232 350.626 215.531 350.63 215.855L350.639 216.668C350.645 217.184 351.055 217.568 351.493 217.468L370.682 213.091C371.119 212.991 371.372 212.46 371.211 211.982L370.872 210.974C370.763 210.65 370.489 210.439 370.188 210.447Z" fill="#3F3D56" />
                            </g>
                            <g id="Group_6">
                                <path id="Vector_25" d="M334.281 218.114L328.632 227.37C328.632 227.37 320.645 258.772 328.632 258.9C336.618 259.028 352.742 242.897 352.742 242.897L350.64 234.48L344.797 236.818L345.966 218.114H334.281Z" fill="#A0616A" />
                                <path id="Vector_26" d="M351.809 194.588C351.809 194.588 333.113 191.293 331.36 203.535C329.607 215.777 328.632 227.371 328.632 227.371L348.887 229.049L351.809 194.588Z" fill="#E6E6E6" />
                                <path id="Vector_27" d="M359.405 240.043C364.151 236.662 366.628 231.996 364.938 229.622C363.248 227.248 358.03 228.065 353.284 231.446C348.538 234.828 346.061 239.494 347.751 241.868C349.441 244.242 354.659 243.425 359.405 240.043Z" fill="#A0616A" />
                            </g>
                        </g>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_205_386">
                        <rect width="666" height="448" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>

    );
}

export default Relax;
