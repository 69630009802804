function ManAndWoman() {
    return (
        <>

            <svg id="man-woman" width="803" height="259" viewBox="0 0 803 259" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_31_169)">
                    <g id="woman">
                        <path id="Vector" d="M781.256 231.5H751.744C751.078 231.498 750.42 231.642 749.816 231.922C749.211 232.201 748.675 232.609 748.245 233.117C747.157 232.917 746.036 232.978 744.976 233.295C743.915 233.612 742.945 234.176 742.145 234.941C741.345 235.705 740.737 236.649 740.372 237.694C740.007 238.739 739.895 239.855 740.046 240.952C740.196 242.048 740.604 243.093 741.237 244.001C741.869 244.91 742.708 245.655 743.685 246.176C744.661 246.697 745.747 246.979 746.854 246.999C747.961 247.019 749.056 246.776 750.051 246.29L752.498 254.245C752.788 255.187 753.373 256.012 754.166 256.598C754.96 257.184 755.92 257.5 756.906 257.5H776.094C777.08 257.5 778.041 257.184 778.834 256.598C779.627 256.012 780.211 255.187 780.501 254.245L785.664 237.468C785.876 236.778 785.924 236.048 785.802 235.337C785.681 234.625 785.394 233.952 784.966 233.372C784.537 232.791 783.978 232.32 783.334 231.995C782.69 231.669 781.978 231.5 781.256 231.5ZM747 245C745.674 245 744.402 244.473 743.464 243.536C742.527 242.598 742 241.326 742 240C742 238.674 742.527 237.402 743.464 236.464C744.402 235.527 745.674 235 747 235C747.091 235 747.176 235.022 747.267 235.027C747.068 235.832 747.092 236.676 747.336 237.468L749.448 244.332C748.704 244.765 747.86 244.995 747 245Z" fill="#F0F0F0" />
                        <path id="Vector_2" d="M546 81.1812V60C546 47.2696 551.057 35.0606 560.059 26.0589C569.061 17.0571 581.27 12 594 12C606.73 12 618.939 17.0571 627.941 26.0589C636.943 35.0606 642 47.2696 642 60V81.1812C641.997 84.0495 640.856 86.7994 638.828 88.8276C636.799 90.8559 634.049 91.9967 631.181 92H566.3L561 80.7368V92H556.819C553.951 91.9967 551.201 90.8559 549.172 88.8276C547.144 86.7994 546.003 84.0495 546 81.1812Z" fill="#2F2E41" />
                        <path id="Vector_3" d="M629.007 148.976L631.074 171.379L632.014 181.558L633.513 197.765L633.702 199.855L634.922 213.018L634.952 213.162C635.721 215.625 637.264 217.773 639.351 219.289C641.439 220.805 643.96 221.607 646.539 221.576L702.22 220.615L717.935 220.342C718.238 220.796 718.534 221.205 718.829 221.569C720.209 223.245 721.926 224.612 723.869 225.581C725.812 226.551 727.937 227.101 730.106 227.196C732.286 227.296 734.462 226.93 736.489 226.121C738.516 225.313 740.347 224.081 741.86 222.508C742.163 222.197 742.458 221.872 742.731 221.531C744.79 218.998 745.995 215.879 746.174 212.62C746.354 209.361 745.499 206.128 743.731 203.385C742.553 201.548 740.997 199.984 739.166 198.798C737.334 197.612 735.27 196.831 733.113 196.508C730.968 196.179 728.778 196.305 726.685 196.878C724.592 197.451 722.643 198.457 720.965 199.833C720.432 200.272 719.933 200.75 719.473 201.264L702.22 198.75L658.771 192.426L656.915 167.357C656.908 167.244 656.9 167.13 656.892 167.009L655.393 146.78C655.142 143.414 653.616 140.27 651.126 137.991C648.636 135.713 645.37 134.47 641.995 134.518C640.884 134.53 639.779 134.685 638.708 134.98C637.508 135.306 636.361 135.8 635.3 136.45C633.182 137.737 631.468 139.595 630.356 141.81C629.243 144.025 628.775 146.508 629.007 148.976Z" fill="#9E616A" />
                        <path id="Vector_4" d="M595.942 93.1617C613.324 93.1617 627.414 79.0713 627.414 61.69C627.414 44.3086 613.324 30.2183 595.942 30.2183C578.561 30.2183 564.47 44.3086 564.47 61.69C564.47 79.0713 578.561 93.1617 595.942 93.1617Z" fill="#9E616A" />
                        <path id="Vector_5" d="M637.904 114.143L643.626 112.236C643.626 112.236 655.071 117.004 655.071 125.587C655.071 134.171 656.978 168.503 656.978 168.503L631.229 171.365L637.904 114.143Z" fill="#6C63FF" />
                        <path id="Vector_6" d="M516.168 256.96H538.859L546.622 191.911L549.432 168.418L549.818 165.176L551.893 147.787C552.005 145.604 551.517 143.432 550.484 141.505C549.451 139.578 547.911 137.97 546.03 136.856C544.149 135.741 542 135.161 539.814 135.179C537.627 135.197 535.488 135.812 533.625 136.957C533.157 137.246 532.709 137.567 532.285 137.919C530.983 138.99 529.922 140.324 529.173 141.835C528.423 143.345 528.002 144.997 527.938 146.682L525.893 165.805L516.168 256.96Z" fill="#9E616A" />
                        <path id="Vector_7" d="M550.201 168.503L524.451 165.642C524.451 165.642 526.359 131.309 526.359 122.726C526.359 114.143 537.803 109.375 537.803 109.375L543.525 111.282L550.201 168.503Z" fill="#6C63FF" />
                        <path id="Vector_8" d="M541.85 256.96H649.493C646.082 243.925 641.429 231.246 635.596 219.1C634.649 217.169 634.089 216.139 634.089 216.139C634.089 216.139 588.314 215.238 559.701 226.681C557.876 227.469 556.24 228.636 554.9 230.104C549.946 235.224 545.615 244.835 541.85 256.96Z" fill="#2F2E41" />
                        <path id="Vector_9" d="M564.471 103.652C564.471 103.652 563.562 103.165 617.874 105.143L640.606 111.403L643.627 112.235C643.627 112.235 640.766 169.457 633.136 179.947C625.507 190.438 627.414 196.16 629.321 197.114C631.229 198.067 635.997 196.16 633.136 200.928C630.275 205.697 626.46 202.836 630.275 205.697C634.09 208.558 635.997 219.049 635.997 219.049L552.073 230.493C552.073 230.493 549.212 193.299 541.582 174.225C533.953 155.151 532.045 147.522 532.045 147.522L537.767 109.374L564.471 103.652Z" fill="#6C63FF" />
                        <path id="Vector_10" d="M562 44C562 35.7783 565.266 27.8933 571.08 22.0797C576.893 16.2661 584.778 13 593 13C601.222 13 609.107 16.2661 614.92 22.0797C620.734 27.8933 624 35.7783 624 44V58H562V44Z" fill="#2F2E41" />
                        <path id="Vector_11" d="M698.5 249.092V251.856C698.5 253.475 697.856 255.027 696.712 256.172C695.567 257.317 694.015 257.96 692.396 257.96H489.372C487.753 257.96 486.201 257.317 485.056 256.172C483.911 255.027 483.268 253.475 483.268 251.856V249.092C483.268 247.473 483.911 245.92 485.056 244.776C486.201 243.631 487.753 242.988 489.372 242.987H492.977C492.849 242.541 492.783 242.08 492.78 241.616V137.995C492.781 136.638 493.32 135.336 494.28 134.376C495.24 133.416 496.542 132.876 497.9 132.875H683.868C685.226 132.876 686.527 133.416 687.487 134.376C688.447 135.336 688.987 136.638 688.988 137.995V241.616C688.988 242.08 688.922 242.542 688.791 242.987H692.396C694.015 242.988 695.567 243.631 696.712 244.776C697.856 245.92 698.5 247.473 698.5 249.092Z" fill="#3F3D56" />
                        <path id="Vector_12" d="M594.884 191.42C592.589 191.42 590.346 190.74 588.438 189.465C586.53 188.19 585.042 186.378 584.164 184.258C583.286 182.137 583.056 179.804 583.504 177.554C583.952 175.303 585.057 173.236 586.679 171.613C588.302 169.99 590.37 168.885 592.62 168.437C594.871 167.99 597.204 168.219 599.324 169.098C601.444 169.976 603.257 171.463 604.532 173.371C605.807 175.279 606.487 177.523 606.487 179.817C606.484 182.894 605.26 185.843 603.085 188.018C600.909 190.193 597.96 191.417 594.884 191.42ZM594.884 170.214C592.985 170.214 591.128 170.778 589.549 171.833C587.97 172.888 586.739 174.388 586.012 176.142C585.285 177.897 585.095 179.828 585.466 181.691C585.836 183.554 586.751 185.265 588.094 186.608C589.437 187.951 591.148 188.865 593.011 189.236C594.873 189.606 596.804 189.416 598.559 188.689C600.314 187.963 601.813 186.732 602.869 185.153C603.924 183.573 604.487 181.717 604.487 179.817C604.484 177.271 603.471 174.83 601.671 173.03C599.871 171.23 597.43 170.217 594.884 170.214Z" fill="white" />
                        <path id="Vector_13" d="M590.884 186.42C596.74 186.42 601.487 181.673 601.487 175.817C601.487 169.961 596.74 165.214 590.884 165.214C585.028 165.214 580.281 169.961 580.281 175.817C580.281 181.673 585.028 186.42 590.884 186.42Z" fill="white" />
                    </g>
                    <g id="plant">
                        <path id="Vector_14" d="M410.914 186.5C410.914 184.918 410.445 183.371 409.566 182.055C408.687 180.74 407.437 179.714 405.975 179.109C404.514 178.503 402.905 178.345 401.353 178.654C399.801 178.962 398.376 179.724 397.257 180.843C396.138 181.962 395.376 183.387 395.068 184.939C394.759 186.491 394.917 188.1 395.523 189.561C396.128 191.023 397.154 192.273 398.469 193.152C399.785 194.031 401.332 194.5 402.914 194.5C403.013 194.5 403.11 194.489 403.209 194.485C405.187 200.915 406.244 207.593 406.348 214.32C406.424 217.673 406.232 221.028 405.775 224.351C405.427 226.708 404.777 229.414 402.436 230.515C400.69 231.336 402.211 233.924 403.95 233.105C406.59 231.863 407.874 229.079 408.45 226.358C409.106 222.85 409.409 219.284 409.353 215.715C409.384 208.296 408.301 200.915 406.138 193.818C407.558 193.193 408.766 192.168 409.614 190.869C410.462 189.569 410.913 188.052 410.914 186.5Z" fill="#E6E6E6" />
                        <path id="Path 482" d="M408.47 234.707C408.47 234.707 397.743 208.303 429.923 188.5L408.47 234.707Z" fill="#E6E6E6" />
                        <path id="Path 483" d="M407.895 234.227C407.895 234.227 404.623 215.407 379.296 215.568L407.895 234.227Z" fill="#E6E6E6" />
                        <path id="Vector_15" d="M425.84 257.34H381.86C381.016 257.34 380.19 257.103 379.474 256.655C378.759 256.208 378.184 255.568 377.815 254.809L368.239 235.126C367.906 234.441 367.752 233.681 367.792 232.92C367.833 232.158 368.066 231.419 368.471 230.773C368.875 230.126 369.438 229.593 370.105 229.224C370.772 228.854 371.522 228.66 372.285 228.66H435.416C436.178 228.66 436.928 228.854 437.595 229.224C438.263 229.593 438.825 230.126 439.229 230.773C439.634 231.419 439.867 232.158 439.908 232.92C439.948 233.681 439.794 234.441 439.461 235.126L429.885 254.809C429.516 255.568 428.941 256.208 428.226 256.655C427.511 257.103 426.684 257.34 425.84 257.34Z" fill="#F0F0F0" />
                    </g>
                    <g id="man">
                        <path id="Vector_16" d="M62.2563 211H32.7436C32.0778 210.998 31.4198 211.142 30.8155 211.422C30.2112 211.701 29.6752 212.109 29.2451 212.617C28.1565 212.417 27.0361 212.478 25.9756 212.795C24.9152 213.112 23.9451 213.676 23.1449 214.441C22.3446 215.205 21.7371 216.149 21.3723 217.194C21.0074 218.239 20.8955 219.355 21.0458 220.452C21.1961 221.548 21.6043 222.593 22.2369 223.501C22.8695 224.41 23.7085 225.155 24.6849 225.676C25.6613 226.197 26.7474 226.479 27.854 226.499C28.9606 226.519 30.0562 226.276 31.0507 225.79L34.4985 254.745C34.7884 255.687 35.373 256.512 36.1663 257.098C36.9597 257.684 37.92 258 38.9062 258H58.0942C59.0803 258 60.0405 257.684 60.8338 257.098C61.627 256.512 62.2115 255.687 62.5014 254.745L66.664 216.968C66.8762 216.278 66.9235 215.548 66.8023 214.837C66.681 214.125 66.3944 213.452 65.9657 212.872C65.537 212.291 64.978 211.82 64.3337 211.495C63.6895 211.169 62.9779 211 62.2563 211ZM27.9999 224.5C26.6739 224.5 25.4021 223.973 24.4644 223.036C23.5267 222.098 22.9999 220.826 22.9999 219.5C22.9999 218.174 23.5267 216.902 24.4644 215.964C25.4021 215.027 26.6739 214.5 27.9999 214.5C28.0913 214.5 28.1762 214.522 28.2665 214.527C28.0677 215.332 28.0918 216.176 28.3364 216.968L30.4482 223.832C29.7042 224.265 28.8603 224.495 27.9999 224.5Z" fill="#F0F0F0" />
                        <path id="Vector_17" d="M167.075 200.927L147.64 127.442L145.39 118.959L147.5 117L183.722 99.0552L235.482 100.344L265.869 115.944L266.033 127.442L267.533 231.033L273.595 256.46H162.419L171.051 215.964L167.075 200.927Z" fill="#6C63FF" />
                        <path id="Vector_18" d="M294.929 256.46H265.955L257.956 198.7L260.05 127.442L260.338 117.748L265.869 115.943C270.038 118.988 273.484 122.914 275.962 127.442C279.516 133.629 282.469 142.495 281.735 154.416C281.735 154.416 282.391 168.492 281.766 177.866C281.337 184.272 288.812 224.76 294.929 256.46Z" fill="#6C63FF" />
                        <path id="Vector_19" d="M167.176 200.192L167.075 200.927L159.388 256.46H126.071C128.743 224.995 131.883 185.655 131.461 179.351C130.836 169.985 131.493 155.9 131.493 155.9C130.696 142.988 134.235 133.676 138.164 127.442C140.141 124.273 142.576 121.415 145.39 118.959C146.014 118.411 146.671 117.902 147.358 117.436L152.889 119.232L154.334 127.442L167.176 200.192Z" fill="#6C63FF" />
                        <path id="Vector_20" d="M171.017 50.7663C171.017 57.8626 173.121 64.7996 177.063 70.6999C181.006 76.6003 186.61 81.199 193.166 83.9147C199.722 86.6303 206.936 87.3408 213.896 85.9564C220.856 84.572 227.249 81.1548 232.267 76.137C237.285 71.1191 240.702 64.726 242.086 57.7661C243.471 50.8062 242.76 43.592 240.045 37.0358C237.329 30.4797 232.73 24.8761 226.83 20.9336C220.929 16.9911 213.993 14.8868 206.896 14.8868C197.404 14.8631 188.291 18.6111 181.563 25.3063C174.834 32.0015 171.04 41.0954 171.017 50.5875C171.017 50.6471 171.017 50.7067 171.017 50.7663Z" fill="#FFB7B7" />
                        <path id="Vector_21" d="M321.5 250.738C321.5 252.786 320.686 254.75 319.238 256.198C317.79 257.647 315.826 258.46 313.778 258.46H107.222C105.174 258.46 103.21 257.646 101.762 256.198C100.314 254.75 99.5 252.786 99.5 250.738C99.5 248.69 100.314 246.726 101.762 245.278C103.21 243.83 105.174 243.016 107.222 243.016H109.514C109.383 242.556 109.315 242.081 109.312 241.602V136.149C109.312 134.37 110.018 132.664 111.276 131.407C112.533 130.149 114.239 129.442 116.018 129.442H304.982C306.761 129.442 308.467 130.149 309.724 131.407C310.982 132.664 311.688 134.37 311.688 136.148V241.602C311.689 242.081 311.621 242.557 311.486 243.016H313.778C314.792 243.016 315.796 243.216 316.733 243.604C317.67 243.992 318.521 244.561 319.238 245.278C319.955 245.995 320.524 246.846 320.912 247.783C321.3 248.72 321.5 249.724 321.5 250.738Z" fill="#3F3D56" />
                        <path id="Vector_22" d="M214.5 189.671C212.14 189.671 209.832 188.971 207.869 187.659C205.906 186.348 204.376 184.484 203.473 182.303C202.569 180.121 202.333 177.721 202.794 175.406C203.254 173.091 204.391 170.964 206.06 169.295C207.729 167.625 209.856 166.488 212.172 166.028C214.487 165.567 216.887 165.803 219.068 166.707C221.249 167.61 223.113 169.14 224.425 171.103C225.737 173.066 226.437 175.374 226.437 177.734C226.433 180.899 225.175 183.933 222.937 186.171C220.699 188.409 217.665 189.667 214.5 189.671ZM214.5 167.798C212.535 167.798 210.614 168.381 208.98 169.473C207.346 170.565 206.073 172.117 205.321 173.932C204.568 175.748 204.372 177.746 204.755 179.673C205.139 181.601 206.085 183.371 207.475 184.761C208.864 186.15 210.635 187.097 212.562 187.48C214.49 187.863 216.488 187.667 218.303 186.914C220.119 186.162 221.671 184.889 222.762 183.255C223.854 181.621 224.437 179.7 224.437 177.734C224.434 175.1 223.386 172.575 221.523 170.712C219.66 168.849 217.135 167.801 214.5 167.798Z" fill="white" />
                        <path id="Vector_23" d="M210.5 184.671C216.54 184.671 221.437 179.775 221.437 173.735C221.437 167.695 216.54 162.798 210.5 162.798C204.46 162.798 199.564 167.695 199.564 173.735C199.564 179.775 204.46 184.671 210.5 184.671Z" fill="white" />
                        <path id="Vector_24" d="M247.538 20.9623C244.646 18.7289 242.295 15.8718 240.66 12.6036C240.076 11.3615 239.271 10.2361 238.284 9.28248L231.5 14.5L235.646 7.27876C225.027 0.860176 204.292 4.26917 204.292 4.26917C182.722 -7.21692 174.801 7.12097 172.04 16.2138C170.946 19.5043 169.017 22.4548 166.442 24.7767C161.121 29.8502 169.5 39.7187 169.5 39.7187V40.4161L171.5 54C171.5 54 173.5 45 178.778 38.6756C182.105 34.6882 187.521 32.3174 190.037 32.3665C222.5 33 228.646 34.2855 228.646 34.2855C228.646 34.2855 234.142 37.2381 236.569 38.4784C244.413 42.4865 242.5 57 242.5 57L248.361 31.241C249.714 24.9969 248.963 22.132 247.538 20.9623Z" fill="#2F2E41" />
                    </g>
                    <path id="Vector_25" d="M802 258.5H1C0.734784 258.5 0.480429 258.395 0.292892 258.207C0.105356 258.02 0 257.765 0 257.5C0 257.235 0.105356 256.98 0.292892 256.793C0.480429 256.605 0.734784 256.5 1 256.5H802C802.265 256.5 802.52 256.605 802.707 256.793C802.895 256.98 803 257.235 803 257.5C803 257.765 802.895 258.02 802.707 258.207C802.52 258.395 802.265 258.5 802 258.5Z" fill="#3F3D56" />
                </g>
                <defs>
                    <clipPath id="clip0_31_169">
                        <rect width="803" height="259" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>

    );
}

export default ManAndWoman;
