function LilHand() {
    return (
        <>
            <svg id="lilHand" width="81" height="83" viewBox="0 0 81 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3839 74.7689C25.5277 80.5749 41.5779 88.0582 56.6281 71.5434C71.6783 55.0286 77.8136 48.7495 79 47.6743C78.7881 43.2446 75.8984 36.5526 66.0344 45.2229C53.7045 56.0607 60.9499 54.1254 56.6281 54.3834C53.1706 54.5899 53.7468 46.9001 54.4671 43.0295L62.6024 13.2254C61.6702 8.36562 58.1026 1.09738 51.2893 10.903C48.1369 21.4312 44.5523 33.5249 43.1541 38.2557L42.5185 6.12925C40.6965 2.2586 35.9086 -3.16032 31.3325 6.12925C31.3325 16.5542 30.9936 31.2025 30.8241 37.2235L23.4515 11.8062C20.4855 7.97856 14.1722 3.16175 12.6469 14.5157C15.4942 26.076 17.9009 36.3634 18.7483 40.062L10.4859 26.2567C6.37595 23.3752 -1.15763 20.4765 1.58802 31.9336C7.94369 43.0295 12.774 47.4163 12.6469 60.0604C13.1553 64.4987 13.5367 65.9524 13.6638 66.1244" stroke="white" stroke-width="2" />
                <circle cx="15.3015" cy="69.9" r="1.3" fill="#D9D9D9" />
            </svg>

        </>

    );
}

export default LilHand;
