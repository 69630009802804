import React from 'react';

function Bike() {
    return (
        <>
            <svg width="79" id='bike' height="47" viewBox="0 0 79 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_249_76)">
                    <path d="M15.5284 17.7961C9.20371 18.7458 2.97443 22.6094 0.779955 28.9241C-1.36939 35.1088 1.01515 41.9769 6.80318 45.0782C12.6229 48.1966 20.0576 47.4146 25.3192 43.4969C30.3193 39.7739 32.7142 33.4068 31.1432 27.2829C29.4098 20.5259 22.2059 15.2754 15.2933 17.7735C14.2124 18.1641 14.6784 19.9184 15.771 19.5235C21.1353 17.585 26.9437 21.1181 28.9315 26.2578C30.9564 31.493 29.4523 37.4737 25.3143 41.1824C21.1293 44.9332 14.9516 46.181 9.66408 44.361C3.85925 42.3629 0.651324 36.6197 2.16348 30.5779C3.75877 24.2038 9.90575 20.4621 16.0061 19.5462C17.1458 19.3751 16.6614 17.626 15.5284 17.7961Z" fill="white" />
                    <path d="M62.9073 17.7961C56.5826 18.7458 50.3533 22.6094 48.1589 28.9241C46.0095 35.1088 48.3941 41.9769 54.1821 45.0782C60.0018 48.1966 67.4365 47.4146 72.6981 43.4969C77.6982 39.7739 80.0931 33.4068 78.5221 27.2829C76.7887 20.5259 69.5848 15.2754 62.6722 17.7735C61.5913 18.1641 62.0573 19.9184 63.1499 19.5235C68.5142 17.585 74.3226 21.1181 76.3105 26.2578C78.3353 31.493 76.8312 37.4737 72.6932 41.1824C68.5082 44.9332 62.3305 46.181 57.043 44.361C51.2382 42.3629 48.0302 36.6197 49.5424 30.5779C51.1377 24.2038 57.2847 20.4621 63.385 19.5462C64.5247 19.3751 64.0403 17.626 62.9073 17.7961Z" fill="white" />
                    <path d="M16.7717 32.8679C21.6182 24.0956 25.1764 14.6515 27.3134 4.84002C27.4274 4.31688 27.0615 3.63098 26.4471 3.69135C23.3016 4.00043 20.9992 6.91964 21.9955 10.1153C23.0624 13.5374 26.6155 13.5652 29.5391 13.0686C30.6769 12.8753 30.1944 11.1261 29.0614 11.3185C27.3603 11.6075 25.0358 11.9624 23.9827 10.1911C22.7865 8.17921 24.2336 5.72373 26.4471 5.50623L25.5808 4.35755C23.4754 14.0235 19.9952 23.3089 15.2201 31.9519C14.6561 32.9728 16.2075 33.889 16.7717 32.8679Z" fill="white" />
                    <path d="M25.8343 10.5015C34.677 10.8223 43.5198 11.1431 52.3625 11.4638C53.519 11.5058 53.5174 9.69085 52.3625 9.64896C43.5198 9.32819 34.677 9.0074 25.8343 8.68663C24.6778 8.64468 24.6795 10.4596 25.8343 10.5015Z" fill="white" />
                    <path d="M44.2523 35.4596C47.6959 25.8345 51.1396 16.2095 54.5833 6.58449C54.7049 6.24438 54.5093 5.71204 54.1704 5.55971C53.3725 5.20109 52.6027 5.00755 51.7413 4.85655C50.9308 4.71449 49.6333 4.60017 49.1276 3.81331C48.1485 2.29026 50.3387 1.91671 51.2844 1.95302C52.109 1.98468 52.8176 2.27865 53.6003 2.41503C54.3132 2.53926 55.1443 2.29194 55.8452 2.17695C56.3257 2.09813 56.805 2.00923 57.287 1.94032C57.4547 1.91633 57.8746 1.92247 58.0305 1.84772C58.5988 1.57527 58.3632 2.59037 57.834 1.27145C58.2627 2.3399 60.0015 1.87286 59.5666 0.788979C59.0704 -0.447753 57.3757 0.0989252 56.3844 0.25763C55.6448 0.376045 54.8001 0.643426 54.0483 0.615283C53.2728 0.586245 52.0415 0.147116 51.0146 0.130843C49.0838 0.100256 46.6349 1.34571 47.1098 3.67907C47.7104 6.62991 51.1955 6.19727 53.2635 7.12681L52.8506 6.10203C49.4069 15.7271 45.9633 25.3521 42.5196 34.9771C42.1259 36.0774 43.8616 36.5514 44.2523 35.4596Z" fill="white" />
                    <path d="M63.3628 31.664C60.0729 24.4365 56.783 17.209 53.4931 9.98154C53.0106 8.9216 51.4614 9.84264 51.9415 10.8975C55.2314 18.125 58.5213 25.3525 61.8112 32.58C62.2937 33.64 63.8429 32.7189 63.3628 31.664Z" fill="white" />
                    <path d="M23.8241 14.7011C30.6481 21.2276 36.9729 28.2795 42.7036 35.8038C43.3989 36.7167 44.9605 35.8138 44.2551 34.8878C38.4284 27.2374 32.0342 20.0548 25.0946 13.4178C24.2552 12.615 22.9823 13.8961 23.8241 14.7011Z" fill="white" />
                    <path d="M42.6137 35.8381C43.8529 37.3712 44.8596 39.2972 45.4421 41.2123L46.3085 40.0637C45.1597 40.0726 44.0109 40.0816 42.8621 40.0905C42.3923 40.0941 41.9418 40.5052 41.9637 40.9979C41.9853 41.4864 42.3585 41.9093 42.8621 41.9054C44.0109 41.8964 45.1597 41.8875 46.3085 41.8786C46.8772 41.8741 47.3483 41.3002 47.1748 40.7299C46.4862 38.4657 45.3657 36.3876 43.8843 34.5548C43.5858 34.1855 42.9194 34.2185 42.6137 34.5548C42.2572 34.9471 42.2953 35.4442 42.6137 35.8381Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_249_76">
                        <rect width="79" height="47" fill="white" />
                    </clipPath>
                </defs>
            </svg>


        </>

    );
}

export default Bike;
