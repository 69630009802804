import React from 'react';

function Gamer() {
    return (
        <>

            <svg width="676" height="463" viewBox="0 0 676 463" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M580.353 193.32C589.67 203.105 600.066 204.666 611.539 198.004C606.724 190.278 605.578 181.875 606.861 173.023H584.511C585.501 180.802 585.235 188.045 580.353 193.32Z" fill="#FFB8B8" />
                <path d="M617.257 449.373L629.212 443.129L634.41 377.033V342.164L606.861 341.644L614.658 376.513L617.257 449.373Z" fill="#FFB8B8" />
                <path d="M582.603 449.373L570.649 443.129L565.451 377.033V342.164L592.999 341.644L585.202 376.513L582.603 449.373Z" fill="#FFB8B8" />
                <path d="M564.239 349.45L593.346 346.848L598.024 320.306L603.742 346.328L635.448 344.246C636.141 331.6 634.381 318.586 630.25 305.213L560.601 303.652L564.239 349.45Z" fill="#2F2E41" />
                <path d="M533.985 249.77L498.972 222.775C498.609 222.494 498.209 222.265 497.784 222.092L492.695 220.018C488.608 218.353 480.822 212.815 484.619 207.112C487.681 202.514 499.399 210.751 500.841 213.802C501.296 214.763 502.043 215.554 502.975 216.063L510.091 219.967C510.264 220.062 510.442 220.147 510.625 220.221L537.715 231.215C538.466 231.52 539.279 231.641 540.086 231.568C540.893 231.495 541.671 231.231 542.355 230.796L547.266 227.678C547.965 227.234 548.761 226.968 549.586 226.902C550.411 226.836 551.24 226.972 552 227.298C552.761 227.624 553.43 228.132 553.951 228.775C554.472 229.419 554.828 230.181 554.989 230.993L556.612 239.196C556.845 240.377 556.654 241.603 556.072 242.656C555.49 243.71 554.554 244.523 553.43 244.952L538.906 250.495C538.092 250.805 537.213 250.902 536.351 250.775C535.49 250.648 534.675 250.302 533.985 249.77Z" fill="#FFB8B8" />
                <path d="M540.489 230.101C550.183 227.498 549.165 241.059 556.561 247.726L571.103 236.232L562.68 195.402C560.42 196.048 558.342 197.212 556.608 198.801C554.875 200.39 553.535 202.362 552.693 204.559L540.489 230.101Z" fill="#6C63FF" />
                <path d="M558.521 306.254H631.81C619.949 274.251 621.104 241.335 631.203 207.793C631.372 207.236 631.343 206.638 631.118 206.101C630.894 205.563 630.49 205.121 629.975 204.851L608.94 193.841C600.682 199.221 591.808 197.567 582.431 190.198L559.561 196.443C565.787 233.882 565.964 270.553 558.521 306.254Z" fill="#6C63FF" />
                <path d="M576.848 461.597L546.306 462.893C544.976 462.95 543.672 462.511 542.645 461.663C541.619 460.815 540.942 459.616 540.745 458.298C540.546 456.967 540.851 455.609 541.6 454.491C542.349 453.374 543.488 452.576 544.794 452.256L549.974 450.984L570.335 439.168C573.351 442.296 577.505 444.94 582.583 447.192L582.643 455.5C582.654 457.07 582.056 458.583 580.975 459.72C579.894 460.858 578.414 461.53 576.848 461.597Z" fill="#2F2E41" />
                <path d="M622.758 461.132L653.3 462.429C654.63 462.485 655.934 462.047 656.96 461.199C657.987 460.35 658.664 459.152 658.861 457.834C659.06 456.502 658.755 455.145 658.006 454.027C657.257 452.909 656.118 452.112 654.812 451.792L649.631 450.52L629.271 438.704C626.254 441.831 622.1 444.476 617.022 446.728L616.963 455.036C616.952 456.606 617.55 458.119 618.631 459.256C619.712 460.393 621.191 461.066 622.758 461.132Z" fill="#2F2E41" />
                <path d="M593.347 182.391C602.533 182.391 609.979 174.935 609.979 165.737C609.979 156.54 602.533 149.083 593.347 149.083C584.161 149.083 576.714 156.54 576.714 165.737C576.714 174.935 584.161 182.391 593.347 182.391Z" fill="#FFB8B8" />
                <path d="M619.336 301.05L613.841 311.748C613.356 312.692 613.076 313.728 613.019 314.789C612.963 315.849 613.131 316.909 613.513 317.899C613.895 318.889 614.482 319.788 615.235 320.535C615.989 321.282 616.892 321.86 617.884 322.233C618.935 322.628 620.061 322.782 621.179 322.683C622.296 322.585 623.378 322.238 624.344 321.666C625.311 321.095 626.137 320.314 626.762 319.381C627.387 318.448 627.796 317.386 627.958 316.275L630.251 300.53L638.567 260.977L638.048 241.2H621.415C627.088 255.909 624.274 277.606 619.336 301.05Z" fill="#FFB8B8" />
                <path d="M618.815 243.282H641.166L637.922 218.739C637.482 215.411 636.216 212.246 634.241 209.533C632.265 206.82 629.642 204.646 626.612 203.208L618.815 243.282Z" fill="#6C63FF" />
                <path d="M611.03 156.564C611.362 154.339 611.222 152.07 610.62 149.903C610.018 147.735 608.966 145.72 607.534 143.987C604.696 140.624 599.939 137.872 592.048 139.171C574.83 142.003 569.865 156.355 571.551 159.965C573.238 163.574 578.409 171.941 578.409 171.941C578.409 171.941 574.614 163.658 578.733 161.973C582.851 160.287 598.79 153.645 604.353 156.637C605.928 157.429 607.265 158.624 608.228 160.102C609.191 161.579 609.747 163.286 609.837 165.048L611.03 156.564Z" fill="#2F2E41" />
                <path d="M326.347 87.2536L319.66 90.6512C311.957 75.4649 301.752 61.6874 289.474 49.8981C229.18 -8.1402 132.969 -6.2427 75.0044 54.1279C17.0394 114.499 18.9345 210.831 79.2289 268.869C90.7045 279.938 103.851 289.125 118.185 296.094L114.914 302.85C92.1941 291.804 72.4254 275.504 57.2411 255.296C42.0569 235.088 31.8929 211.553 27.589 186.634C23.2851 161.715 24.9649 136.129 32.4894 111.989C40.014 87.8489 53.1674 65.8481 70.8628 47.8042C88.5583 29.7603 110.288 16.1913 134.256 8.21833C158.225 0.245374 183.744 -1.90269 208.705 1.95162C233.667 5.80593 257.354 15.552 277.809 30.3845C298.265 45.217 314.903 64.7103 326.347 87.2536Z" fill="#E6E6E6" />
                <path d="M100.1 226.941C90.264 237.186 84.8949 250.923 85.1745 265.132C85.454 279.34 91.3591 292.855 101.591 302.704C111.823 312.553 125.543 317.928 139.733 317.649C153.923 317.369 167.421 311.456 177.258 301.211L186.471 291.616L109.313 217.346L100.1 226.941Z" fill="#E6E6E6" />
                <path d="M104.143 211.896C101.954 214.181 100.759 217.243 100.822 220.41C100.884 223.576 102.198 226.588 104.476 228.786L175.244 296.906C177.525 299.102 180.584 300.3 183.747 300.238C186.911 300.176 189.92 298.857 192.113 296.574C194.306 294.29 195.502 291.227 195.44 288.06C195.378 284.892 194.061 281.879 191.78 279.684L121.012 211.563C118.729 209.371 115.671 208.175 112.509 208.237C109.347 208.3 106.338 209.615 104.143 211.896Z" fill="#535461" />
                <path d="M316.398 156.297L325.611 146.701C335.447 136.457 340.816 122.719 340.537 108.511C340.257 94.3026 334.352 80.7875 324.12 70.9386C319.054 66.0619 313.078 62.2322 306.534 59.6679C299.989 57.1037 293.004 55.8552 285.978 55.9938C271.788 56.2737 258.29 62.1863 248.453 72.4309L239.24 82.0263L316.398 156.297Z" fill="#E6E6E6" />
                <path d="M233.598 77.0687C231.408 79.3543 230.213 82.4161 230.276 85.5824C230.338 88.7488 231.652 91.7611 233.93 93.9585L304.698 162.079C306.98 164.272 310.038 165.468 313.2 165.405C316.362 165.341 319.369 164.023 321.561 161.741C323.753 159.458 324.949 156.397 324.888 153.231C324.827 150.065 323.513 147.053 321.235 144.857L250.466 76.736C248.183 74.5438 245.125 73.3477 241.963 73.41C238.801 73.4724 235.792 74.7882 233.598 77.0687Z" fill="#535461" />
                <path d="M263.451 234.494V228.959C263.451 228.959 287.572 215.374 307.171 228.959V233.991L263.451 234.494Z" fill="#535461" />
                <path d="M483.053 234.494V228.959C483.053 228.959 507.174 215.374 526.772 228.959V233.991L483.053 234.494Z" fill="#535461" />
                <path d="M335.814 233.991H312.195C312.195 233.991 306.165 226.947 286.064 227.45C265.963 227.953 261.943 233.991 261.943 233.991C261.943 233.991 242.093 247.325 237.571 263.929C237.571 263.929 213.198 337.138 212.696 394.498C212.193 451.858 234.304 459.909 245.862 461.418C257.42 462.928 278.024 460.915 298.125 410.096L306.668 380.41C306.668 380.41 308.175 362.799 326.266 373.869C344.357 384.938 360.437 367.831 360.437 367.831H428.278C428.278 367.831 450.891 381.919 471.495 370.85C471.495 370.85 479.535 360.284 487.073 396.008C487.073 396.008 510.189 469.469 545.868 461.921C581.547 454.374 577.527 392.989 577.527 392.989C577.527 392.989 572.753 299.15 553.155 265.941C553.155 265.941 534.31 231.475 521.747 229.966C521.747 229.966 494.611 224.934 484.058 229.462L478.028 234.494L335.814 233.991Z" fill="#E6E6E6" />
                <path d="M281.04 327.075C305.185 327.075 324.759 307.477 324.759 283.3C324.759 259.124 305.185 239.526 281.04 239.526C256.894 239.526 237.32 259.124 237.32 283.3C237.32 307.477 256.894 327.075 281.04 327.075Z" fill="#535461" />
                <path d="M509.184 327.075C533.33 327.075 552.904 307.477 552.904 283.3C552.904 259.124 533.33 239.526 509.184 239.526C485.039 239.526 465.465 259.124 465.465 283.3C465.465 307.477 485.039 327.075 509.184 327.075Z" fill="#535461" />
                <path d="M288.657 273.539L282.828 278.702C282.334 279.137 281.699 279.377 281.041 279.377C280.384 279.377 279.749 279.137 279.255 278.702L273.416 273.539C273.13 273.287 272.901 272.978 272.743 272.631C272.586 272.284 272.503 271.908 272.501 271.527V257.322C272.502 256.605 272.788 255.918 273.295 255.411C273.801 254.905 274.488 254.62 275.205 254.62H286.888C287.604 254.619 288.291 254.902 288.798 255.408C289.305 255.914 289.59 256.601 289.592 257.317V271.516C289.589 271.901 289.504 272.28 289.342 272.629C289.181 272.978 288.948 273.288 288.657 273.539Z" fill="#E6E6E6" />
                <path d="M288.657 293.057L282.828 287.899C282.334 287.464 281.699 287.224 281.041 287.224C280.384 287.224 279.749 287.464 279.255 287.899L273.416 293.062C273.13 293.314 272.901 293.623 272.743 293.97C272.586 294.317 272.503 294.693 272.501 295.074V309.263C272.5 309.98 272.783 310.668 273.288 311.175C273.793 311.683 274.479 311.969 275.195 311.97H286.888C287.604 311.972 288.291 311.688 288.798 311.183C289.305 310.677 289.59 309.99 289.592 309.273V295.079C289.589 294.695 289.504 294.316 289.342 293.967C289.181 293.618 288.948 293.308 288.657 293.057Z" fill="#E6E6E6" />
                <path d="M271.296 290.938L276.452 285.092C276.886 284.597 277.126 283.961 277.126 283.303C277.126 282.644 276.886 282.008 276.452 281.514L271.296 275.667C271.044 275.382 270.735 275.153 270.388 274.995C270.042 274.837 269.666 274.754 269.286 274.752H255.115C254.398 274.753 253.712 275.039 253.206 275.546C252.7 276.054 252.416 276.742 252.416 277.459V289.157C252.415 289.874 252.698 290.562 253.203 291.069C253.708 291.577 254.394 291.863 255.11 291.864H269.291C269.671 291.86 270.047 291.775 270.392 291.616C270.738 291.456 271.046 291.225 271.296 290.938Z" fill="#E6E6E6" />
                <path d="M290.789 290.938L285.643 285.086C285.207 284.593 284.967 283.957 284.967 283.298C284.967 282.639 285.207 282.003 285.643 281.509L290.799 275.662C291.05 275.376 291.359 275.147 291.706 274.989C292.052 274.831 292.428 274.749 292.809 274.747H306.98C307.696 274.745 308.382 275.029 308.889 275.534C309.396 276.04 309.682 276.727 309.683 277.444V289.152C309.685 289.869 309.402 290.556 308.897 291.064C308.391 291.572 307.706 291.858 306.99 291.859H292.814C292.43 291.858 292.051 291.776 291.702 291.617C291.353 291.458 291.041 291.227 290.789 290.938Z" fill="#E6E6E6" />
                <path d="M340.337 362.799C357.821 362.799 371.995 348.607 371.995 331.1C371.995 313.593 357.821 299.401 340.337 299.401C322.852 299.401 308.678 313.593 308.678 331.1C308.678 348.607 322.852 362.799 340.337 362.799Z" fill="#535461" />
                <path d="M449.886 362.799C467.371 362.799 481.545 348.607 481.545 331.1C481.545 313.593 467.371 299.401 449.886 299.401C432.402 299.401 418.228 313.593 418.228 331.1C418.228 348.607 432.402 362.799 449.886 362.799Z" fill="#535461" />
                <path d="M340.336 352.233C351.993 352.233 361.442 342.772 361.442 331.1C361.442 319.429 351.993 309.968 340.336 309.968C328.68 309.968 319.23 319.429 319.23 331.1C319.23 342.772 328.68 352.233 340.336 352.233Z" fill="#E6E6E6" />
                <path d="M340.337 345.692C337.455 345.692 334.637 344.836 332.24 343.233C329.844 341.63 327.976 339.351 326.873 336.684C325.77 334.018 325.481 331.084 326.044 328.254C326.606 325.423 327.994 322.823 330.032 320.783C332.07 318.742 334.667 317.352 337.494 316.789C340.321 316.226 343.251 316.515 345.914 317.62C348.577 318.724 350.853 320.594 352.454 322.994C354.055 325.393 354.91 328.214 354.91 331.1C354.906 334.969 353.369 338.678 350.637 341.413C347.905 344.149 344.201 345.688 340.337 345.692ZM340.337 317.515C337.653 317.515 335.03 318.312 332.799 319.805C330.568 321.297 328.829 323.419 327.802 325.902C326.775 328.384 326.506 331.115 327.029 333.751C327.553 336.386 328.845 338.807 330.743 340.707C332.64 342.607 335.058 343.9 337.69 344.425C340.322 344.949 343.05 344.68 345.529 343.652C348.008 342.623 350.127 340.882 351.618 338.648C353.109 336.414 353.905 333.787 353.905 331.1C353.901 327.499 352.47 324.046 349.926 321.499C347.383 318.952 343.934 317.519 340.337 317.515Z" fill="#535461" />
                <path d="M449.886 352.233C461.543 352.233 470.992 342.772 470.992 331.1C470.992 319.429 461.543 309.968 449.886 309.968C438.23 309.968 428.78 319.429 428.78 331.1C428.78 342.772 438.23 352.233 449.886 352.233Z" fill="#E6E6E6" />
                <path d="M449.887 345.692C447.004 345.692 444.187 344.836 441.79 343.233C439.394 341.63 437.526 339.351 436.423 336.684C435.32 334.018 435.031 331.084 435.594 328.254C436.156 325.423 437.544 322.823 439.582 320.783C441.62 318.742 444.217 317.352 447.044 316.789C449.87 316.226 452.801 316.515 455.464 317.62C458.126 318.724 460.402 320.594 462.004 322.994C463.605 325.393 464.46 328.214 464.46 331.1C464.455 334.969 462.918 338.678 460.186 341.413C457.454 344.149 453.75 345.688 449.887 345.692ZM449.887 317.515C447.203 317.515 444.58 318.312 442.349 319.805C440.117 321.297 438.378 323.419 437.351 325.902C436.324 328.384 436.056 331.115 436.579 333.751C437.103 336.386 438.395 338.807 440.293 340.707C442.19 342.607 444.608 343.9 447.24 344.425C449.872 344.949 452.6 344.68 455.079 343.652C457.558 342.623 459.677 340.882 461.168 338.648C462.659 336.414 463.455 333.787 463.455 331.1C463.451 327.499 462.02 324.046 459.476 321.499C456.933 318.952 453.484 317.519 449.887 317.515Z" fill="#535461" />
                <path d="M396.117 343.679C401.112 343.679 405.162 339.624 405.162 334.623C405.162 329.621 401.112 325.566 396.117 325.566C391.121 325.566 387.071 329.621 387.071 334.623C387.071 339.624 391.121 343.679 396.117 343.679Z" fill="#535461" />
                <path d="M382.292 306.37C383.383 306.37 384.267 305.485 384.267 304.393C384.267 303.301 383.383 302.415 382.292 302.415C381.202 302.415 380.317 303.301 380.317 304.393C380.317 305.485 381.202 306.37 382.292 306.37Z" fill="#535461" />
                <path d="M389.69 306.37C390.78 306.37 391.665 305.485 391.665 304.393C391.665 303.301 390.78 302.415 389.69 302.415C388.599 302.415 387.715 303.301 387.715 304.393C387.715 305.485 388.599 306.37 389.69 306.37Z" fill="#535461" />
                <path d="M388.704 315.754C389.795 315.754 390.679 314.869 390.679 313.777C390.679 312.685 389.795 311.799 388.704 311.799C387.614 311.799 386.729 312.685 386.729 313.777C386.729 314.869 387.614 315.754 388.704 315.754Z" fill="#535461" />
                <path d="M395.615 316.006C396.725 316.006 397.625 315.105 397.625 313.993C397.625 312.882 396.725 311.98 395.615 311.98C394.504 311.98 393.604 312.882 393.604 313.993C393.604 315.105 394.504 316.006 395.615 316.006Z" fill="#535461" />
                <path d="M402.514 315.754C403.605 315.754 404.489 314.869 404.489 313.777C404.489 312.685 403.605 311.799 402.514 311.799C401.423 311.799 400.539 312.685 400.539 313.777C400.539 314.869 401.423 315.754 402.514 315.754Z" fill="#535461" />
                <path d="M385.744 311.311C386.835 311.311 387.719 310.426 387.719 309.334C387.719 308.242 386.835 307.356 385.744 307.356C384.654 307.356 383.77 308.242 383.77 309.334C383.77 310.426 384.654 311.311 385.744 311.311Z" fill="#535461" />
                <path d="M392.097 311.477C393.207 311.477 394.107 310.576 394.107 309.465C394.107 308.353 393.207 307.452 392.097 307.452C390.987 307.452 390.087 308.353 390.087 309.465C390.087 310.576 390.987 311.477 392.097 311.477Z" fill="#535461" />
                <path d="M398.629 311.477C399.739 311.477 400.639 310.576 400.639 309.465C400.639 308.353 399.739 307.452 398.629 307.452C397.519 307.452 396.619 308.353 396.619 309.465C396.619 310.576 397.519 311.477 398.629 311.477Z" fill="#535461" />
                <path d="M405.474 311.311C406.565 311.311 407.449 310.426 407.449 309.334C407.449 308.242 406.565 307.356 405.474 307.356C404.383 307.356 403.499 308.242 403.499 309.334C403.499 310.426 404.383 311.311 405.474 311.311Z" fill="#535461" />
                <path d="M395.615 306.446C396.725 306.446 397.625 305.545 397.625 304.433C397.625 303.322 396.725 302.42 395.615 302.42C394.504 302.42 393.604 303.322 393.604 304.433C393.604 305.545 394.504 306.446 395.615 306.446Z" fill="#535461" />
                <path d="M402.65 306.446C403.76 306.446 404.66 305.545 404.66 304.433C404.66 303.322 403.76 302.42 402.65 302.42C401.54 302.42 400.64 303.322 400.64 304.433C400.64 305.545 401.54 306.446 402.65 306.446Z" fill="#535461" />
                <path d="M408.434 306.37C409.525 306.37 410.409 305.485 410.409 304.393C410.409 303.301 409.525 302.415 408.434 302.415C407.343 302.415 406.459 303.301 406.459 304.393C406.459 305.485 407.343 306.37 408.434 306.37Z" fill="#535461" />
                <path d="M320.989 238.279C320.06 238.28 319.169 238.65 318.511 239.309C317.854 239.967 317.484 240.859 317.483 241.79V252.356C317.484 253.287 317.854 254.179 318.511 254.838C319.169 255.496 320.06 255.866 320.989 255.867H323.502C324.432 255.866 325.323 255.496 325.98 254.838C326.637 254.179 327.007 253.287 327.008 252.356V241.79C327.007 240.859 326.637 239.967 325.98 239.309C325.323 238.65 324.432 238.28 323.502 238.279H320.989Z" fill="#535461" />
                <path d="M467.726 238.279C466.796 238.28 465.905 238.65 465.248 239.309C464.59 239.967 464.221 240.859 464.22 241.79V252.356C464.221 253.287 464.59 254.179 465.248 254.838C465.905 255.496 466.796 255.866 467.726 255.867H470.239C471.168 255.866 472.059 255.496 472.717 254.838C473.374 254.179 473.744 253.287 473.745 252.356V241.79C473.744 240.859 473.374 239.967 472.717 239.309C472.059 238.65 471.168 238.28 470.239 238.279H467.726Z" fill="#535461" />
                <path d="M509.687 268.709C516.347 268.709 521.747 263.302 521.747 256.633C521.747 249.964 516.347 244.557 509.687 244.557C503.026 244.557 497.626 249.964 497.626 256.633C497.626 263.302 503.026 268.709 509.687 268.709Z" fill="#E6E6E6" />
                <path d="M484.059 294.37C490.719 294.37 496.119 288.963 496.119 282.294C496.119 275.625 490.719 270.218 484.059 270.218C477.398 270.218 471.998 275.625 471.998 282.294C471.998 288.963 477.398 294.37 484.059 294.37Z" fill="#E6E6E6" />
                <path d="M536.32 292.86C542.981 292.86 548.381 287.454 548.381 280.785C548.381 274.115 542.981 268.709 536.32 268.709C529.659 268.709 524.26 274.115 524.26 280.785C524.26 287.454 529.659 292.86 536.32 292.86Z" fill="#E6E6E6" />
                <path d="M511.697 319.528C518.358 319.528 523.758 314.121 523.758 307.452C523.758 300.783 518.358 295.376 511.697 295.376C505.036 295.376 499.637 300.783 499.637 307.452C499.637 314.121 505.036 319.528 511.697 319.528Z" fill="#E6E6E6" />
                <path d="M343.729 231.235C341.034 231.238 338.451 232.311 336.545 234.219C334.639 236.127 333.568 238.714 333.564 241.413V286.445C333.568 289.143 334.639 291.73 336.545 293.638C338.451 295.546 341.034 296.619 343.729 296.622H446.997C449.692 296.619 452.276 295.546 454.181 293.638C456.087 291.73 457.159 289.143 457.162 286.445V241.413C457.159 238.714 456.087 236.127 454.181 234.219C452.276 232.311 449.692 231.238 446.997 231.235H343.729Z" fill="#535461" />
                <path d="M517.88 262.168H501.525L509.421 248.615L517.88 262.168ZM503.275 261.161H516.067L509.45 250.562L503.275 261.161Z" fill="#535461" />
                <path d="M490.842 288.332H477.274V276.256H490.842V288.332ZM478.279 287.326H489.837V277.262H478.279V287.326Z" fill="#535461" />
                <path d="M536.321 288.332C534.83 288.332 533.373 287.889 532.133 287.06C530.894 286.231 529.928 285.052 529.357 283.673C528.786 282.294 528.637 280.776 528.928 279.312C529.219 277.848 529.937 276.503 530.991 275.448C532.045 274.392 533.388 273.674 534.85 273.382C536.313 273.091 537.828 273.241 539.206 273.812C540.583 274.383 541.76 275.35 542.588 276.592C543.417 277.833 543.859 279.292 543.859 280.785C543.857 282.786 543.062 284.704 541.649 286.119C540.235 287.534 538.319 288.33 536.321 288.332ZM536.321 274.244C535.029 274.244 533.766 274.627 532.692 275.346C531.617 276.065 530.78 277.086 530.286 278.281C529.791 279.477 529.662 280.792 529.914 282.061C530.166 283.33 530.788 284.495 531.702 285.41C532.615 286.325 533.779 286.948 535.047 287.2C536.314 287.452 537.627 287.323 538.821 286.828C540.015 286.333 541.035 285.494 541.753 284.419C542.471 283.343 542.854 282.078 542.854 280.785C542.852 279.05 542.163 277.388 540.938 276.162C539.714 274.935 538.053 274.246 536.321 274.244Z" fill="#535461" />
                <path d="M506.273 301.435L505.562 302.147L516.869 313.468L517.58 312.757L506.273 301.435Z" fill="#535461" />
                <path d="M517.128 301.427L505.571 312.498L506.266 313.225L517.823 302.154L517.128 301.427Z" fill="#535461" />
            </svg>

        </>

    );
}

export default Gamer;
