import React from 'react';
import BuildingSection from '../../../anim/BuildingWave';
import './Building.scss';

function Building() {
  return (
    <div className="buildingContainer">
      <BuildingSection/>
    </div>


  );
}

export default Building;
