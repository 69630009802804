import React from "react";

function ProjectsSvg() {
  return (
    <>
      <svg
        width="525"
        height="532"
        viewBox="0 0 525 532"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_210_319)">
          <path
            d="M117.675 88.7438L113.675 109.744L133.618 115.366L131.14 92.946L117.675 88.7438Z"
            fill="#A0616A"
          />
          <path
            d="M0 523.445C0 524.105 0.53003 524.635 1.19006 524.635H523.48C524.14 524.635 524.67 524.105 524.67 523.445C524.67 522.785 524.14 522.255 523.48 522.255H1.19006C0.53003 522.255 0 522.785 0 523.445Z"
            fill="#3F3D56"
          />
          <path
            d="M356.037 238.309H150.369C127.046 238.309 108.139 257.216 108.139 280.539V479.971C108.139 503.294 127.046 522.201 150.369 522.201H356.037C379.36 522.201 398.267 503.294 398.267 479.971V280.539C398.267 257.216 379.36 238.309 356.037 238.309Z"
            fill="white"
          />
          <path
            d="M356.038 523.201H150.369C126.532 523.201 107.139 503.808 107.139 479.971V280.539C107.139 256.702 126.532 237.309 150.369 237.309H356.038C379.875 237.309 399.268 256.702 399.268 280.539V479.971C399.268 503.808 379.874 523.201 356.038 523.201ZM150.369 239.309C127.634 239.309 109.139 257.804 109.139 280.539V479.971C109.139 502.706 127.634 521.201 150.369 521.201H356.038C378.772 521.201 397.268 502.706 397.268 479.971V280.539C397.268 257.804 378.772 239.309 356.038 239.309L150.369 239.309Z"
            fill="#3F3D56"
          />
          <path
            d="M370.935 324.77H288.267C284.761 324.77 281.908 321.917 281.908 318.411C281.908 314.905 284.761 312.052 288.267 312.052H370.935C374.441 312.052 377.294 314.905 377.294 318.411C377.294 321.917 374.441 324.77 370.935 324.77Z"
            fill="#6C63FF"
          />
          <path
            d="M293.382 374.777H213.025C210.999 374.777 209.351 373.129 209.351 371.103C209.351 369.077 210.999 367.43 213.025 367.43H293.382C295.408 367.43 297.055 369.077 297.055 371.103C297.055 373.129 295.408 374.777 293.382 374.777Z"
            fill="#6C63FF"
          />
          <path
            d="M179.805 251.967H133.927C133.756 251.967 133.617 252.106 133.617 252.277V257.618C133.617 257.789 133.756 257.928 133.927 257.928H179.805C179.976 257.928 180.115 257.789 180.115 257.618V252.277C180.115 252.106 179.976 251.967 179.805 251.967Z"
            fill="#6C63FF"
          />
          <path
            d="M334.336 257.928C336.97 257.928 339.105 255.793 339.105 253.159C339.105 250.525 336.97 248.39 334.336 248.39C331.702 248.39 329.567 250.525 329.567 253.159C329.567 255.793 331.702 257.928 334.336 257.928Z"
            fill="#3F3D56"
          />
          <path
            d="M347.451 257.928C350.085 257.928 352.22 255.793 352.22 253.159C352.22 250.525 350.085 248.39 347.451 248.39C344.817 248.39 342.682 250.525 342.682 253.159C342.682 255.793 344.817 257.928 347.451 257.928Z"
            fill="#3F3D56"
          />
          <path
            d="M360.566 257.928C363.2 257.928 365.335 255.793 365.335 253.159C365.335 250.525 363.2 248.39 360.566 248.39C357.932 248.39 355.797 250.525 355.797 253.159C355.797 255.793 357.932 257.928 360.566 257.928Z"
            fill="#3F3D56"
          />
          <path
            d="M149.868 394.445C149.868 395.105 150.398 395.635 151.058 395.635H355.348C356.008 395.635 356.538 395.105 356.538 394.445C356.538 393.785 356.008 393.255 355.348 393.255H151.058C150.398 393.255 149.868 393.785 149.868 394.445Z"
            fill="#3F3D56"
          />
          <path
            d="M212.173 100.917C216.522 97.3234 221.902 96.6545 224.188 99.4227C226.475 102.191 224.802 107.347 220.451 110.942C218.733 112.401 216.69 113.428 214.493 113.936L195.873 128.93L189.023 120.042L208.107 106.205C209.02 104.143 210.415 102.33 212.173 100.917Z"
            fill="#A0616A"
          />
          <path
            d="M104.553 134.747C104.543 134.084 104.549 128.3 108.831 124.644C113.75 120.445 120.691 122.144 123.126 122.741C128.749 124.118 129.21 126.53 134.492 129.009C144.372 133.646 131.992 131.273 137.871 129.786C139.907 129.271 169.184 127.24 176.471 122.755C190.645 114.032 200.302 108.742 200.302 108.742L206.615 121.368C206.615 121.368 197.486 134.062 184.638 143.465C171.459 153.111 164.869 157.934 154.855 159.798C153.992 159.958 132.783 163.579 115.7 150.817C111.803 147.906 104.664 142.572 104.553 134.747Z"
            fill="#E6E6E6"
          />
          <path
            d="M124.137 102.635C136.283 102.635 146.129 92.7888 146.129 80.6426C146.129 68.4963 136.283 58.6499 124.137 58.6499C111.99 58.6499 102.144 68.4963 102.144 80.6426C102.144 92.7888 111.99 102.635 124.137 102.635Z"
            fill="#A0616A"
          />
          <path
            d="M296.375 228.398L273.725 205.214L262.034 217.409L278.908 239.639L295.646 241.553L296.375 228.398Z"
            fill="#A0616A"
          />
          <path
            d="M167.046 184.922C168.371 185.032 172.581 185.875 176.604 183.662C179.351 182.151 180.759 179.904 181.235 179.19C187.136 170.327 209.787 156.505 230.116 155.655C234.719 155.462 237.749 155.996 240.503 157.431C244.409 159.467 245.968 162.383 250.924 169.214C253.635 172.951 256.634 176.471 259.378 180.184C275.44 201.92 281.529 203.626 280.906 210.595C280.216 218.326 271.877 225.662 266.335 224.815C264.066 224.468 263.187 222.89 260.154 219.695C251.263 210.327 247.585 212.058 241.254 204.883C233.864 196.506 234.831 189.56 230.976 189.292C227.4 189.043 225.88 194.968 218.247 202.84C214.753 206.442 212.266 208.094 204.688 213.351C175.177 233.824 174.706 235.263 168.751 236.896C166.145 237.61 147.651 242.68 141.063 234.612C130.754 221.985 155.109 183.93 167.046 184.922Z"
            fill="#2F2E41"
          />
          <path
            d="M289.451 233.637C291.116 232.889 293.064 232.014 293.691 230.003C294.115 228.642 293.871 226.964 292.994 225.805C291.983 224.467 290.486 224.29 290.547 224.054C290.639 223.693 294.178 223.834 298.064 225.208C298.797 225.467 302.119 226.681 306.344 229.979C309.729 232.622 309.274 233.152 311.397 234.676C317.913 239.354 324.025 235.667 327.551 240.054C328.912 241.747 329.492 244.151 328.919 246.094C327.769 249.998 322.176 251.341 319.12 252.034C311.758 253.705 306.186 252.341 300.368 251.361C280.582 248.027 269.464 251.063 267.976 246.479C267.695 245.612 268.152 245.685 269.678 239.319C271.184 233.035 271.134 231.309 272.417 230.806C274.347 230.049 275.643 233.494 280.506 234.673C284.815 235.718 288.371 234.122 289.451 233.637Z"
            fill="#2F2E41"
          />
          <path
            d="M173.508 195.645C174.542 196.48 177.552 199.542 182.125 199.97C185.245 200.262 187.672 199.194 188.467 198.87C198.327 194.85 224.831 196.133 242.129 206.844C246.047 209.27 248.254 211.413 249.727 214.147C251.816 218.025 251.468 221.313 251.733 229.748C251.878 234.363 252.383 238.96 252.568 243.572C253.653 270.577 257.733 275.409 253.305 280.825C248.392 286.835 237.374 288.222 233.263 284.41C231.58 282.848 231.739 281.049 231.024 276.702C228.927 263.958 224.911 263.325 223.703 253.833C222.291 242.751 223.952 235.16 223.952 235.16C223.952 235.16 216.549 236.996 205.812 239.223C200.899 240.242 197.913 240.212 188.69 240.307C152.775 240.676 151.578 241.602 145.733 239.608C143.176 238.736 125.026 232.547 124.105 222.171C122.665 205.934 164.187 188.122 173.508 195.645Z"
            fill="#2F2E41"
          />
          <path
            d="M256.108 304.243L250.385 272.34L233.862 275.866L235.342 303.736L248.117 314.719L256.108 304.243Z"
            fill="#A0616A"
          />
          <path
            d="M247.436 304.689C249.234 305.006 251.337 305.376 252.985 304.064C254.1 303.176 254.84 301.65 254.766 300.198C254.681 298.523 253.541 297.537 253.724 297.375C254.003 297.129 256.853 299.233 259.296 302.551C259.757 303.177 261.824 306.047 263.468 311.149C264.785 315.237 264.111 315.42 265.012 317.873C267.777 325.402 274.904 325.784 275.359 331.394C275.534 333.559 274.664 335.874 273.099 337.16C269.956 339.744 264.574 337.715 261.655 336.573C254.626 333.821 250.781 329.564 246.517 325.486C232.017 311.616 221.113 307.886 222.456 303.257C222.71 302.381 223.047 302.699 227.884 298.288C232.659 293.934 233.587 292.478 234.931 292.782C236.953 293.239 236.091 296.818 239.453 300.524C242.432 303.809 246.271 304.484 247.436 304.689Z"
            fill="#2F2E41"
          />
          <path
            d="M127.135 84.8469C127.991 81.7842 126.916 80.6681 128.012 79.2934C128.012 79.2933 128.453 78.7402 132.396 77.2473C131.233 70.4969 132.104 69.3555 132.104 69.3554C133.108 68.0399 135.119 67.7219 136.488 67.894C139.384 68.2579 139.897 70.8756 141.75 70.8169C144.309 70.7358 147.379 65.6121 146.426 61.1712C145.649 57.5488 142.298 54.8586 141.457 55.3254C140.624 55.7882 137.626 53.7473 136.488 54.1562C135.712 54.4355 132.056 54.064 131.227 54.4485C130.322 54.8685 127.73 57.4833 127.482 57.1539C125.759 54.8644 125.796 54.0651 124.797 53.5716C123.026 52.6973 121.101 54.3134 117.782 55.91C110.952 59.1951 108.952 57.2355 105.798 60.0021C104.204 61.3996 103.35 64.0706 101.706 69.3554C99.4065 76.7462 98.2569 80.4416 99.3673 83.9701C100.5 87.5684 102.167 86.7852 104.629 91.862C107.368 97.5132 106.424 100.798 109.013 102.092C111.014 103.092 114.229 102.456 115.443 100.631C117.522 97.506 112.149 93.279 113.982 88.3545C115.107 85.3316 118.577 83.0395 120.705 83.6778C123.111 84.3994 123.288 88.7135 124.797 88.6467C126.089 88.5896 126.986 85.3806 127.135 84.8469Z"
            fill="#2F2E41"
          />
          <path
            d="M110.474 105.892C114.975 100.846 133.306 106.069 135.027 114.076C135.734 117.37 133.122 118.76 133.858 122.553C134.934 128.099 141.027 127.721 145.549 133.368C151.97 141.384 145.355 149.219 152.564 162.597C154.105 165.455 155.403 167.865 158.118 170.196C164.347 175.547 170.145 173.712 173.317 178.673C175.689 182.383 174.715 186.955 174.486 188.026C173.55 192.418 171.013 193.488 169.225 197.087C166.25 203.075 169.618 207.471 169.81 214.625C170.049 223.576 165.292 235.983 155.78 240.054C145.092 244.628 132.203 237.053 125.966 229.532C122.244 225.044 121.163 220.904 119.535 215.794C112.258 192.957 108.147 195.022 102.582 176.919C96.2206 156.224 94.9699 131.979 102.582 124.306C103.591 123.29 106.368 120.939 108.136 116.707C110.515 111.012 108.46 108.151 110.474 105.892Z"
            fill="#E6E6E6"
          />
          <path
            d="M176.974 223.359C180.463 227.792 181.004 233.186 178.182 235.406C175.36 237.626 170.245 235.832 166.755 231.396C165.337 229.644 164.359 227.577 163.903 225.369L149.356 206.398L156.873 200.665L171.784 219.168C173.824 220.13 175.603 221.567 176.974 223.359Z"
            fill="#A0616A"
          />
          <path
            d="M123.698 125.123C124.345 124.979 130.008 123.805 134.461 127.251C139.576 131.209 139.328 138.351 139.241 140.856C139.04 146.642 136.773 147.586 135.424 153.262C132.9 163.88 137.707 173.749 140.362 179.201C141.281 181.088 144.234 186.863 150.112 193.082C161.544 205.177 171.744 211.542 171.744 211.542L160.786 221.282C160.786 221.282 143.331 215.962 131.504 205.303C119.372 194.369 100.715 155.684 96.8471 146.26C96.514 145.449 101.232 158.642 110.241 139.315C112.295 134.906 116.06 126.829 123.698 125.123Z"
            fill="#E6E6E6"
          />
          <path
            d="M273.945 421.686C266.98 422.754 261.885 427.213 262.565 431.645C263.245 436.078 269.442 438.805 276.41 437.736C279.199 437.347 281.854 436.296 284.154 434.67L313.608 429.774L311.642 418.132L282.254 422.29C279.573 421.428 276.723 421.221 273.945 421.686Z"
            fill="#FFB6B6"
          />
          <path
            d="M400.87 385.395L389.449 409.63L332.035 428.957L284.392 431.499L283.199 420.056L334.832 409.26L387.815 384.223L400.87 385.395Z"
            fill="#E6E6E6"
          />
          <path
            d="M294.976 477.416L284.722 503.351L271.453 494.304L279.294 467.162L294.976 477.416Z"
            fill="#FFB6B6"
          />
          <path
            d="M252.152 467.765L225.01 493.701L235.867 505.161L263.612 483.447L252.152 467.765Z"
            fill="#FFB6B6"
          />
          <path
            d="M405.957 383.323L405.655 381.212L398.719 367.641L375.799 373.673L380.021 392.37L405.957 383.323Z"
            fill="#FFB6B6"
          />
          <path
            d="M396.306 473.797L397.011 482.856C397.011 482.856 399.925 520.843 376.402 523.859C352.879 526.875 342.625 529.89 328.753 506.367L307.039 467.765L294.356 482.856L277.484 469.575C277.484 469.575 289.548 422.528 299.198 420.719C300.404 420.493 301.611 420.38 302.798 420.355C310.788 420.188 318.319 424.096 323.099 430.5L350.466 467.162L360.418 462.638L396.306 473.797Z"
            fill="#2F2E41"
          />
          <path
            d="M266.024 502.145L271.453 491.891L287.135 502.748C287.135 502.748 290.754 516.018 285.325 517.827C279.897 519.637 264.818 517.224 264.818 517.224C264.818 517.224 237.073 523.256 236.47 517.827C235.867 512.399 249.136 511.192 249.136 511.192L266.024 502.145Z"
            fill="#2F2E41"
          />
          <path
            d="M325.134 494.907L283.215 468.494L261.802 487.669L247.327 470.781C247.327 470.781 273.866 431.576 281.707 429.766C289.548 427.957 302.214 426.75 302.214 426.75L340.816 462.337L325.134 494.907Z"
            fill="#2F2E41"
          />
          <path
            d="M224.406 491.288L238.279 506.367L236.45 507.932C236.45 507.932 232.851 523.607 224.406 518.304C215.962 513.002 215.359 511.795 215.359 511.795C215.359 511.795 191.233 517.224 188.82 507.573C186.408 497.923 193.042 499.732 193.042 499.732L199.067 503.351L224.406 491.288Z"
            fill="#2F2E41"
          />
          <path
            d="M383.037 380.307C393.697 380.307 402.338 371.666 402.338 361.006C402.338 350.347 393.697 341.705 383.037 341.705C372.377 341.705 363.736 350.347 363.736 361.006C363.736 371.666 372.377 380.307 383.037 380.307Z"
            fill="#FFB6B6"
          />
          <path
            d="M378.513 388.45L406.259 380.006C406.259 380.006 417.115 405.338 415.306 416.798C413.496 428.258 397.011 482.856 397.011 482.856L360.419 462.638C360.419 462.638 359.815 450.575 358.006 445.147C356.197 439.718 350.467 423.131 363.133 409.259C375.799 395.386 378.513 388.45 378.513 388.45Z"
            fill="#E6E6E6"
          />
          <path
            d="M317.448 515.102C312.933 520.511 312.064 527.226 315.507 530.099C318.95 532.972 325.4 530.915 329.917 525.503C331.75 523.366 333.046 520.821 333.694 518.081L352.536 494.918L343.358 487.491L324.077 510.056C321.496 511.184 319.223 512.914 317.448 515.102Z"
            fill="#FFB6B6"
          />
          <path
            d="M399.795 400.619L406.353 426.595L374.256 477.974L341.49 510.905L330.87 502.094L363.915 460.977L388.963 408L399.795 400.619Z"
            fill="#E6E6E6"
          />
          <path
            d="M365.243 357.689C370.193 357.677 370.475 357.71 371.275 357.689C377.415 357.527 379.02 355.112 382.132 355.276C387.686 355.57 380.355 383.417 377.307 396.291C374.317 408.917 384.422 420.231 385.148 421.02C395.404 432.175 413.42 434.215 416.512 429.465C419.014 425.62 410.146 419.774 412.29 411.37C414.385 403.155 424.061 404.03 426.765 396.291C429.598 388.186 420.079 384.099 414.099 364.324C410.665 352.967 411.272 350.851 408.068 343.816C399.502 325.014 357.514 333.02 362.228 350.451C363.14 353.823 360.713 357.7 365.243 357.689Z"
            fill="#2F2E41"
          />
          <path
            d="M426.877 128.561H224.026C218.289 128.561 213.623 123.894 213.623 118.157V10.4035C213.623 4.66733 218.289 0 224.026 0H426.877C432.613 0 437.28 4.66732 437.28 10.4035V118.157C437.28 123.894 432.613 128.561 426.877 128.561Z"
            fill="white"
          />
          <path
            d="M426.877 128.561H224.026C218.289 128.561 213.623 123.894 213.623 118.157V10.4035C213.623 4.66733 218.289 0 224.026 0H426.877C432.613 0 437.28 4.66732 437.28 10.4035V118.157C437.28 123.894 432.613 128.561 426.877 128.561ZM224.026 1.73731C219.248 1.73731 215.36 5.62505 215.36 10.4035V118.157C215.36 122.936 219.248 126.824 224.026 126.824H426.877C431.655 126.824 435.543 122.936 435.543 118.157V10.4035C435.543 5.62505 431.655 1.73731 426.877 1.73731H224.026Z"
            fill="#3F3D56"
          />
          <path
            d="M411.221 13.0298C412.66 13.0298 413.827 11.8631 413.827 10.4238C413.827 8.9846 412.66 7.81787 411.221 7.81787C409.781 7.81787 408.615 8.9846 408.615 10.4238C408.615 11.8631 409.781 13.0298 411.221 13.0298Z"
            fill="#3F3D56"
          />
          <path
            d="M418.17 13.0298C419.609 13.0298 420.776 11.8631 420.776 10.4238C420.776 8.9846 419.609 7.81787 418.17 7.81787C416.731 7.81787 415.564 8.9846 415.564 10.4238C415.564 11.8631 416.731 13.0298 418.17 13.0298Z"
            fill="#3F3D56"
          />
          <path
            d="M425.119 13.0298C426.558 13.0298 427.725 11.8631 427.725 10.4238C427.725 8.9846 426.558 7.81787 425.119 7.81787C423.68 7.81787 422.513 8.9846 422.513 10.4238C422.513 11.8631 423.68 13.0298 425.119 13.0298Z"
            fill="#3F3D56"
          />
          <path
            d="M228.39 52.5536C227.671 52.5536 227.087 53.1381 227.087 53.8566C227.087 54.2069 227.222 54.531 227.468 54.7702C227.715 55.0238 228.04 55.1595 228.39 55.1595H423.382C424.1 55.1595 424.685 54.5751 424.685 53.8566C424.685 53.5062 424.549 53.1822 424.304 52.943C424.056 52.6893 423.732 52.5536 423.382 52.5536H228.39Z"
            fill="#E6E6E6"
          />
          <path
            d="M399.059 52.1193V55.5939H228.39C227.912 55.5939 227.478 55.4028 227.165 55.0814C226.843 54.7687 226.652 54.3343 226.652 53.8566C226.652 52.901 227.434 52.1193 228.39 52.1193H399.059Z"
            fill="#6C63FF"
          />
          <path
            d="M421.21 45.6044H403.837C401.921 45.6044 400.362 44.0461 400.362 42.1298C400.362 40.2135 401.921 38.6552 403.837 38.6552H421.21C423.126 38.6552 424.685 40.2135 424.685 42.1298C424.685 44.0461 423.126 45.6044 421.21 45.6044Z"
            fill="#E6E6E6"
          />
          <path
            d="M307.416 27.3626H229.693C227.777 27.3626 226.218 25.8043 226.218 23.888C226.218 21.9717 227.777 20.4134 229.693 20.4134H307.416C309.332 20.4134 310.891 21.9717 310.891 23.888C310.891 25.8043 309.332 27.3626 307.416 27.3626Z"
            fill="#E6E6E6"
          />
          <path
            d="M228.39 91.6431C227.671 91.6431 227.087 92.2275 227.087 92.946C227.087 93.2964 227.222 93.6204 227.468 93.8597C227.715 94.1133 228.04 94.249 228.39 94.249H423.382C424.1 94.249 424.685 93.6645 424.685 92.946C424.685 92.5957 424.549 92.2717 424.304 92.0324C424.056 91.7788 423.732 91.6431 423.382 91.6431H228.39Z"
            fill="#E6E6E6"
          />
          <path
            d="M332.173 91.2087V94.6834H228.39C227.912 94.6834 227.478 94.4922 227.165 94.1708C226.843 93.8581 226.652 93.4238 226.652 92.946C226.652 91.9905 227.434 91.2087 228.39 91.2087H332.173Z"
            fill="#6C63FF"
          />
          <path
            d="M421.21 84.6938H403.837C401.921 84.6938 400.362 83.1355 400.362 81.2192C400.362 79.3029 401.921 77.7446 403.837 77.7446H421.21C423.126 77.7446 424.685 79.3029 424.685 81.2192C424.685 83.1355 423.126 84.6938 421.21 84.6938Z"
            fill="#E6E6E6"
          />
        </g>
        <defs>
          <clipPath id="clip0_210_319">
            <rect width="524.67" height="531.397" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default ProjectsSvg;
