function AboutSvgSlider() {
    return (
        <>
            <svg width="500" height="145" id="aboutSvgSlider" viewBox="0 0 500 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M500 0H0C500 0 476.727 141.934 500 145V0Z" fill="#7338D3" />
            </svg>
        </>

    );
}

export default AboutSvgSlider;
