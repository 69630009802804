import React from "react";

function PapersSvg() {
  return (
    <>
      <svg
        width="597"
        height="503"
        viewBox="0 0 597 503"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_404_113)">
          <path
            d="M495.014 405.901L296.95 315.535L398.936 92.2697L597 182.635L495.014 405.901Z"
            fill="#F2F2F2"
          />
          <path
            d="M525.186 276.195L375.246 207.786C374.964 207.657 374.744 207.421 374.635 207.131C374.527 206.84 374.538 206.518 374.667 206.236C374.796 205.954 375.032 205.734 375.323 205.626C375.614 205.517 375.936 205.529 376.218 205.658L526.158 274.067C526.44 274.196 526.66 274.431 526.768 274.722C526.877 275.013 526.866 275.335 526.737 275.617C526.608 275.899 526.372 276.119 526.081 276.227C525.79 276.336 525.468 276.324 525.186 276.195Z"
            fill="white"
          />
          <path
            d="M533.524 257.943L383.584 189.533C383.444 189.469 383.318 189.379 383.213 189.266C383.109 189.154 383.027 189.022 382.973 188.878C382.92 188.734 382.895 188.581 382.9 188.427C382.906 188.274 382.941 188.123 383.005 187.983C383.069 187.843 383.16 187.718 383.272 187.613C383.385 187.508 383.517 187.427 383.661 187.373C383.805 187.319 383.958 187.294 384.112 187.3C384.265 187.305 384.416 187.341 384.556 187.405L534.496 255.814C534.636 255.878 534.762 255.969 534.866 256.081C534.971 256.194 535.053 256.326 535.106 256.469C535.16 256.613 535.185 256.766 535.18 256.92C535.174 257.074 535.138 257.225 535.075 257.364C535.011 257.504 534.92 257.63 534.807 257.735C534.695 257.839 534.563 257.921 534.419 257.975C534.275 258.028 534.122 258.053 533.968 258.048C533.814 258.042 533.663 258.006 533.524 257.943Z"
            fill="white"
          />
          <path
            d="M541.861 239.69L391.922 171.281C391.782 171.217 391.656 171.126 391.551 171.014C391.446 170.901 391.365 170.769 391.311 170.625C391.257 170.482 391.233 170.328 391.238 170.175C391.244 170.021 391.279 169.87 391.343 169.731C391.407 169.591 391.498 169.465 391.61 169.36C391.723 169.256 391.855 169.174 391.999 169.12C392.143 169.067 392.296 169.042 392.449 169.047C392.603 169.053 392.754 169.088 392.894 169.152L542.834 237.562C543.116 237.691 543.335 237.927 543.443 238.217C543.551 238.507 543.539 238.829 543.41 239.111C543.282 239.393 543.046 239.612 542.756 239.721C542.465 239.829 542.144 239.818 541.861 239.69Z"
            fill="white"
          />
          <path
            d="M550.198 221.437L400.259 153.028C400.119 152.964 399.993 152.874 399.888 152.761C399.783 152.649 399.702 152.517 399.648 152.373C399.594 152.229 399.569 152.076 399.575 151.922C399.58 151.769 399.616 151.618 399.68 151.478C399.744 151.338 399.835 151.212 399.947 151.108C400.06 151.003 400.192 150.921 400.336 150.868C400.48 150.814 400.633 150.789 400.786 150.795C400.94 150.8 401.091 150.836 401.231 150.9L551.171 219.309C551.31 219.373 551.436 219.463 551.541 219.576C551.646 219.688 551.727 219.82 551.781 219.964C551.835 220.108 551.86 220.261 551.854 220.415C551.849 220.568 551.813 220.719 551.749 220.859C551.686 220.999 551.595 221.125 551.482 221.229C551.37 221.334 551.238 221.416 551.094 221.469C550.95 221.523 550.797 221.548 550.643 221.542C550.489 221.537 550.338 221.501 550.198 221.437Z"
            fill="white"
          />
          <path
            d="M517.651 292.691L367.711 224.281C367.571 224.218 367.445 224.127 367.34 224.014C367.236 223.902 367.154 223.77 367.1 223.626C367.046 223.482 367.022 223.329 367.027 223.176C367.033 223.022 367.068 222.871 367.132 222.731C367.196 222.592 367.287 222.466 367.399 222.361C367.512 222.256 367.644 222.175 367.788 222.121C367.932 222.067 368.085 222.043 368.239 222.048C368.392 222.053 368.543 222.089 368.683 222.153L518.623 290.562C518.905 290.691 519.125 290.927 519.233 291.218C519.342 291.508 519.33 291.83 519.202 292.112C519.073 292.395 518.837 292.614 518.546 292.723C518.255 292.831 517.933 292.82 517.651 292.691Z"
            fill="white"
          />
          <path
            d="M542.689 194.663L425.567 141.226C424.908 140.943 424.376 140.427 424.072 139.777C423.769 139.127 423.715 138.388 423.921 137.701C424.047 137.328 424.25 136.985 424.517 136.695C424.784 136.405 425.109 136.174 425.47 136.017C425.832 135.861 426.223 135.782 426.617 135.786C427.011 135.789 427.4 135.876 427.759 136.04L544.845 189.46C545.517 189.745 546.058 190.268 546.367 190.929C546.675 191.589 546.727 192.34 546.514 193.037C546.384 193.406 546.179 193.744 545.911 194.029C545.644 194.313 545.32 194.54 544.96 194.692C544.6 194.845 544.212 194.922 543.821 194.917C543.43 194.911 543.044 194.825 542.689 194.663Z"
            fill="white"
          />
          <path
            d="M92.0332 182.635L290.097 92.2697L392.083 315.535L194.019 405.901L92.0332 182.635Z"
            fill="#F2F2F2"
          />
          <path
            d="M163.848 276.195L313.788 207.786C313.928 207.722 314.054 207.631 314.158 207.519C314.263 207.407 314.345 207.275 314.398 207.131C314.452 206.987 314.477 206.834 314.472 206.68C314.466 206.527 314.43 206.376 314.367 206.236C314.303 206.096 314.212 205.97 314.099 205.866C313.987 205.761 313.855 205.679 313.711 205.626C313.567 205.572 313.414 205.547 313.26 205.553C313.106 205.558 312.955 205.594 312.816 205.658L162.876 274.067C162.736 274.131 162.61 274.221 162.505 274.334C162.401 274.446 162.319 274.578 162.265 274.722C162.212 274.866 162.187 275.019 162.192 275.173C162.198 275.326 162.233 275.477 162.297 275.617C162.361 275.757 162.452 275.883 162.564 275.987C162.677 276.092 162.809 276.173 162.953 276.227C163.097 276.281 163.25 276.306 163.404 276.3C163.557 276.295 163.708 276.259 163.848 276.195Z"
            fill="white"
          />
          <path
            d="M155.51 257.943L305.45 189.533C305.732 189.404 305.952 189.169 306.061 188.878C306.169 188.587 306.158 188.265 306.029 187.983C305.9 187.701 305.664 187.481 305.373 187.373C305.082 187.265 304.76 187.276 304.478 187.405L154.538 255.814C154.398 255.878 154.272 255.969 154.167 256.081C154.063 256.194 153.981 256.326 153.927 256.469C153.874 256.613 153.849 256.766 153.854 256.92C153.86 257.074 153.895 257.225 153.959 257.364C154.023 257.504 154.114 257.63 154.226 257.735C154.339 257.839 154.471 257.921 154.615 257.975C154.759 258.028 154.912 258.053 155.066 258.048C155.219 258.042 155.37 258.006 155.51 257.943Z"
            fill="white"
          />
          <path
            d="M147.172 239.69L297.112 171.281C297.394 171.152 297.614 170.916 297.722 170.625C297.831 170.335 297.82 170.013 297.691 169.731C297.562 169.448 297.326 169.229 297.035 169.12C296.744 169.012 296.422 169.023 296.14 169.152L146.2 237.562C145.918 237.691 145.699 237.926 145.59 238.217C145.482 238.508 145.493 238.829 145.622 239.111C145.751 239.393 145.987 239.613 146.277 239.721C146.568 239.83 146.89 239.819 147.172 239.69Z"
            fill="white"
          />
          <path
            d="M138.834 221.437L288.774 153.028C288.914 152.964 289.04 152.874 289.145 152.761C289.249 152.649 289.331 152.517 289.385 152.373C289.439 152.229 289.463 152.076 289.458 151.922C289.452 151.769 289.417 151.618 289.353 151.478C289.289 151.338 289.198 151.212 289.086 151.108C288.973 151.003 288.841 150.921 288.697 150.868C288.553 150.814 288.4 150.789 288.246 150.795C288.093 150.8 287.942 150.836 287.802 150.9L137.862 219.309C137.58 219.438 137.36 219.674 137.252 219.964C137.143 220.255 137.155 220.577 137.283 220.859C137.412 221.141 137.648 221.361 137.939 221.469C138.23 221.578 138.552 221.566 138.834 221.437Z"
            fill="white"
          />
          <path
            d="M171.383 292.691L321.323 224.281C321.605 224.153 321.825 223.917 321.934 223.626C322.042 223.336 322.031 223.014 321.902 222.731C321.773 222.449 321.537 222.23 321.246 222.121C320.955 222.013 320.633 222.024 320.351 222.153L170.411 290.562C170.271 290.626 170.145 290.717 170.041 290.829C169.936 290.942 169.854 291.074 169.8 291.218C169.747 291.362 169.722 291.515 169.727 291.668C169.733 291.822 169.768 291.973 169.832 292.112C169.896 292.252 169.987 292.378 170.099 292.483C170.212 292.587 170.344 292.669 170.488 292.723C170.632 292.776 170.785 292.801 170.939 292.796C171.092 292.79 171.243 292.755 171.383 292.691Z"
            fill="white"
          />
          <path
            d="M146.345 194.663L263.467 141.226C264.126 140.943 264.658 140.427 264.961 139.777C265.265 139.127 265.319 138.388 265.113 137.701C264.987 137.328 264.784 136.985 264.517 136.695C264.25 136.405 263.925 136.174 263.563 136.017C263.202 135.861 262.811 135.782 262.417 135.786C262.022 135.789 261.633 135.876 261.274 136.04L144.188 189.46C143.517 189.745 142.975 190.268 142.667 190.929C142.359 191.589 142.306 192.34 142.519 193.037C142.649 193.406 142.855 193.744 143.122 194.029C143.39 194.313 143.714 194.54 144.074 194.692C144.434 194.845 144.822 194.922 145.213 194.917C145.604 194.911 145.989 194.825 146.345 194.663Z"
            fill="white"
          />
          <path
            d="M19.7997 446.263C21.0502 426.904 31.8431 407.263 49.8104 399.922C43.7524 420.13 45.0421 441.829 53.4513 461.179C56.7215 468.63 61.1349 476.577 58.9401 484.412C57.5745 489.287 53.7569 493.181 49.3828 495.734C45.0084 498.286 40.0678 499.672 35.1886 501.033L34.2636 501.895C25.3662 484.653 18.5492 465.622 19.7997 446.263Z"
            fill="#F0F0F0"
          />
          <path
            d="M50.014 400.274C40.0444 412.866 34.2983 428.278 33.5918 444.319C33.3784 447.77 33.7105 451.233 34.576 454.58C35.5246 457.814 37.3222 460.734 39.7822 463.039C42.0327 465.233 44.589 467.218 46.2665 469.921C47.1046 471.317 47.6379 472.875 47.8313 474.492C48.0248 476.109 47.8742 477.748 47.3892 479.303C46.2816 483.208 43.8361 486.482 41.3333 489.601C38.5545 493.064 35.6132 496.617 34.6077 501.049C34.4858 501.586 33.6592 501.366 33.7808 500.83C35.5302 493.118 42.6381 488.376 45.7443 481.339C47.1938 478.056 47.662 474.317 45.9262 471.044C44.4083 468.183 41.774 466.134 39.4761 463.932C37.0433 461.719 35.1898 458.945 34.0773 455.851C33.044 452.558 32.5789 449.114 32.7019 445.666C32.8549 437.898 34.2291 430.202 36.7741 422.861C39.6321 414.466 43.906 406.622 49.4108 399.667C49.7517 399.237 50.3526 399.847 50.014 400.274Z"
            fill="white"
          />
          <path
            d="M33.5147 439.141C30.8377 438.657 28.42 437.238 26.6933 435.137C24.9666 433.036 24.0434 430.39 24.0885 427.672C24.0913 427.561 24.1371 427.455 24.2162 427.377C24.2953 427.3 24.4015 427.256 24.5125 427.255C24.6234 427.254 24.7305 427.296 24.811 427.372C24.8915 427.448 24.9393 427.553 24.9442 427.664C24.8975 430.195 25.7578 432.659 27.3696 434.612C28.9815 436.566 31.2389 437.878 33.7343 438.314C34.2765 438.409 34.0537 439.235 33.5147 439.141Z"
            fill="white"
          />
          <path
            d="M38.4938 462.076C43.0912 458.949 46.3405 454.206 47.5938 448.791C47.7175 448.254 48.5442 448.474 48.4206 449.01C47.1059 454.643 43.7145 459.572 38.9232 462.816C38.4663 463.124 38.0394 462.383 38.4938 462.076Z"
            fill="white"
          />
          <path
            d="M40.4722 415.228C41.4689 415.631 42.5502 415.78 43.6189 415.662C44.6875 415.544 45.7102 415.162 46.595 414.552C47.0477 414.237 47.4741 414.979 47.0243 415.291C46.0437 415.961 44.9137 416.381 43.7333 416.514C42.5528 416.647 41.3578 416.489 40.2525 416.054C40.1449 416.022 40.0536 415.95 39.9972 415.853C39.9408 415.755 39.9235 415.64 39.9489 415.531C39.9785 415.422 40.0501 415.329 40.1481 415.272C40.2461 415.215 40.3625 415.199 40.4722 415.228Z"
            fill="white"
          />
          <path
            d="M107.746 430.932C107.469 431.136 107.192 431.341 106.915 431.552C103.204 434.319 99.7233 437.383 96.5084 440.714C96.2557 440.967 96.0035 441.228 95.7587 441.488C88.0987 449.594 82.0111 459.051 77.8055 469.379C76.1358 473.489 74.7814 477.721 73.7541 482.038C72.3357 487.999 71.2724 494.575 68.061 499.573C67.7318 500.098 67.3704 500.602 66.9788 501.082L35.3872 502.956C35.3133 502.924 35.2394 502.899 35.1652 502.868L33.9072 503C33.9447 502.774 33.9883 502.54 34.0258 502.313C34.0469 502.182 34.0751 502.05 34.0963 501.919C34.1126 501.831 34.1293 501.743 34.139 501.663C34.1444 501.634 34.15 501.605 34.1558 501.583C34.1655 501.503 34.1831 501.429 34.1932 501.357C34.4338 500.048 34.684 498.74 34.9437 497.43C34.9433 497.423 34.9433 497.423 34.9499 497.415C36.9549 487.444 39.8105 477.552 44.0776 468.407C44.206 468.132 44.3337 467.85 44.4766 467.574C46.4229 463.463 48.7022 459.517 51.2916 455.777C52.7161 453.734 54.2432 451.764 55.867 449.875C60.0721 445.002 65.018 440.82 70.5233 437.483C81.4991 430.833 94.4809 427.764 106.807 430.698C107.122 430.774 107.431 430.849 107.746 430.932Z"
            fill="#F0F0F0"
          />
          <path
            d="M107.699 431.337C92.1529 435.392 78.2806 444.24 68.0526 456.623C65.8033 459.25 63.9825 462.214 62.657 465.408C61.4664 468.56 61.1422 471.974 61.7181 475.294C62.1929 478.4 63.0385 481.523 62.7494 484.691C62.5774 486.31 62.0647 487.875 61.2452 489.282C60.4256 490.689 59.3177 491.908 57.9939 492.857C54.7571 495.309 50.832 496.452 46.9547 497.436C42.6497 498.529 38.1608 499.596 34.6879 502.53C34.2671 502.885 33.7398 502.212 34.16 501.857C40.2023 496.753 48.7346 497.243 55.4536 493.494C58.5888 491.744 61.2156 489.04 61.8009 485.383C62.3127 482.185 61.4435 478.964 60.9355 475.823C60.3262 472.593 60.5176 469.262 61.4931 466.122C62.6517 462.872 64.3552 459.842 66.5309 457.163C71.3327 451.052 77.0659 445.735 83.5206 441.404C90.8599 436.421 98.9979 432.73 107.583 430.489C108.114 430.351 108.227 431.199 107.699 431.337Z"
            fill="white"
          />
          <path
            d="M71.1108 452.442C69.2649 450.445 68.1893 447.857 68.0763 445.14C67.9633 442.424 68.8201 439.756 70.4937 437.613C70.8339 437.18 71.5224 437.688 71.1818 438.121C69.6196 440.114 68.8217 442.6 68.9321 445.129C69.0426 447.658 70.054 450.065 71.784 451.914C72.1594 452.316 71.4841 452.842 71.1108 452.442Z"
            fill="white"
          />
          <path
            d="M61.2698 473.751C66.8243 474.02 72.2764 472.188 76.539 468.619C76.9611 468.265 77.4885 468.938 77.0669 469.292C72.6242 472.998 66.9466 474.893 61.1671 474.6C60.6162 474.571 60.7219 473.723 61.2698 473.751Z"
            fill="white"
          />
          <path
            d="M91.0724 437.535C91.6255 438.456 92.3989 439.226 93.3233 439.775C94.2476 440.324 95.2939 440.635 96.3681 440.68C96.9191 440.701 96.8128 441.55 96.2654 441.528C95.0788 441.473 93.9237 441.129 92.9011 440.524C91.8785 439.92 91.0195 439.075 90.3992 438.063C90.3327 437.972 90.3032 437.86 90.3167 437.748C90.3302 437.637 90.3856 437.534 90.4718 437.462C90.5613 437.393 90.6746 437.362 90.787 437.375C90.8995 437.389 91.002 437.446 91.0724 437.535Z"
            fill="white"
          />
          <path
            d="M491.878 407.778H195.714V73.9292H491.878V407.778Z"
            fill="white"
          />
          <path
            d="M491.878 407.778H195.714V73.9292H491.878V407.778ZM197.306 406.186H490.286V75.5206H197.306L197.306 406.186Z"
            fill="#CACACA"
          />
          <path
            d="M455.898 230.245H231.694C231.485 230.245 231.278 230.204 231.085 230.124C230.891 230.044 230.716 229.927 230.568 229.779C230.42 229.631 230.303 229.456 230.223 229.263C230.143 229.069 230.102 228.863 230.102 228.654C230.102 228.445 230.143 228.238 230.223 228.045C230.303 227.852 230.42 227.676 230.568 227.528C230.716 227.381 230.891 227.263 231.085 227.183C231.278 227.103 231.485 227.062 231.694 227.062H455.898C456.321 227.062 456.726 227.23 457.024 227.528C457.323 227.827 457.491 228.232 457.491 228.654C457.491 229.076 457.323 229.48 457.024 229.779C456.726 230.077 456.321 230.245 455.898 230.245Z"
            fill="#E4E4E4"
          />
          <path
            d="M455.898 202.952H231.694C231.272 202.952 230.867 202.784 230.568 202.486C230.269 202.187 230.102 201.782 230.102 201.36C230.102 200.938 230.269 200.534 230.568 200.235C230.867 199.937 231.272 199.769 231.694 199.769H455.898C456.321 199.769 456.726 199.937 457.024 200.235C457.323 200.534 457.491 200.938 457.491 201.36C457.491 201.782 457.323 202.187 457.024 202.486C456.726 202.784 456.321 202.952 455.898 202.952Z"
            fill="#E4E4E4"
          />
          <path
            d="M455.898 175.659H231.694C231.485 175.659 231.278 175.617 231.085 175.537C230.891 175.458 230.716 175.34 230.568 175.193C230.42 175.045 230.303 174.869 230.223 174.676C230.143 174.483 230.102 174.276 230.102 174.067C230.102 173.858 230.143 173.651 230.223 173.458C230.303 173.265 230.42 173.09 230.568 172.942C230.716 172.794 230.891 172.677 231.085 172.597C231.278 172.517 231.485 172.476 231.694 172.476H455.898C456.321 172.476 456.726 172.644 457.024 172.942C457.323 173.24 457.491 173.645 457.491 174.067C457.491 174.489 457.323 174.894 457.024 175.193C456.726 175.491 456.321 175.659 455.898 175.659Z"
            fill="#E4E4E4"
          />
          <path
            d="M455.898 148.366H231.694C231.272 148.366 230.867 148.198 230.568 147.899C230.269 147.601 230.102 147.196 230.102 146.774C230.102 146.352 230.269 145.947 230.568 145.649C230.867 145.351 231.272 145.183 231.694 145.183H455.898C456.321 145.183 456.726 145.351 457.024 145.649C457.323 145.947 457.491 146.352 457.491 146.774C457.491 147.196 457.323 147.601 457.024 147.899C456.726 148.198 456.321 148.366 455.898 148.366Z"
            fill="#E4E4E4"
          />
          <path
            d="M455.898 254.91H231.694C231.272 254.91 230.867 254.743 230.568 254.444C230.269 254.146 230.102 253.741 230.102 253.319C230.102 252.897 230.269 252.492 230.568 252.194C230.867 251.895 231.272 251.728 231.694 251.728H455.898C456.321 251.728 456.726 251.895 457.024 252.194C457.323 252.492 457.491 252.897 457.491 253.319C457.491 253.741 457.323 254.146 457.024 254.444C456.726 254.743 456.321 254.91 455.898 254.91Z"
            fill="#E4E4E4"
          />
          <path
            d="M455.898 377.361H231.694C231.272 377.361 230.867 377.193 230.568 376.895C230.269 376.596 230.102 376.191 230.102 375.769C230.102 375.347 230.269 374.943 230.568 374.644C230.867 374.346 231.272 374.178 231.694 374.178H455.898C456.321 374.178 456.726 374.346 457.024 374.644C457.323 374.943 457.491 375.347 457.491 375.769C457.491 376.191 457.323 376.596 457.024 376.895C456.726 377.193 456.321 377.361 455.898 377.361Z"
            fill="#E4E4E4"
          />
          <path
            d="M402.191 351.816H285.401C284.621 351.815 283.872 351.505 283.32 350.953C282.768 350.401 282.458 349.654 282.457 348.873V280.215C282.458 279.435 282.768 278.687 283.32 278.135C283.872 277.584 284.621 277.273 285.401 277.272H402.191C402.971 277.273 403.72 277.584 404.272 278.135C404.823 278.687 405.134 279.435 405.135 280.215V348.873C405.134 349.654 404.823 350.401 404.272 350.953C403.72 351.505 402.971 351.815 402.191 351.816Z"
            fill="white"
          />
          <path
            d="M363.188 312.593C362.594 311.957 361.878 311.446 361.082 311.093C360.286 310.739 359.428 310.548 358.557 310.533C357.686 310.517 356.821 310.677 356.014 311.002C355.206 311.327 354.472 311.811 353.855 312.426L320.987 345.274C320.119 346.14 318.943 346.627 317.716 346.629C316.49 346.631 315.312 346.147 314.441 345.284L309.573 340.429C308.972 339.827 308.257 339.35 307.471 339.024C306.684 338.698 305.841 338.531 304.99 338.531C304.139 338.531 303.296 338.698 302.509 339.024C301.723 339.35 301.008 339.827 300.407 340.429L290.985 349.854H396.606V348.511L363.188 312.593Z"
            fill="#6C63FF"
          />
          <path
            d="M402.191 351.816H285.401C284.621 351.815 283.872 351.505 283.32 350.953C282.768 350.401 282.458 349.654 282.457 348.873V280.215C282.458 279.435 282.768 278.687 283.32 278.135C283.872 277.584 284.621 277.273 285.401 277.272H402.191C402.971 277.273 403.72 277.584 404.272 278.135C404.823 278.687 405.134 279.435 405.135 280.215V348.873C405.134 349.654 404.823 350.401 404.272 350.953C403.72 351.505 402.971 351.815 402.191 351.816ZM285.401 279.234C285.141 279.234 284.892 279.338 284.708 279.522C284.524 279.706 284.42 279.955 284.42 280.215V348.873C284.42 349.133 284.524 349.383 284.708 349.567C284.892 349.751 285.141 349.854 285.401 349.854H402.191C402.451 349.854 402.7 349.751 402.884 349.567C403.068 349.383 403.172 349.133 403.172 348.873V280.215C403.172 279.955 403.068 279.706 402.884 279.522C402.7 279.338 402.451 279.234 402.191 279.234L285.401 279.234Z"
            fill="#3F3D56"
          />
          <path
            d="M120.633 340.34C120.929 339.296 120.992 338.201 120.819 337.13C120.646 336.06 120.24 335.04 119.629 334.144C119.019 333.247 118.22 332.495 117.287 331.941C116.355 331.386 115.312 331.043 114.232 330.935L108.113 305.294L98.7334 315.282L105.982 338.1C105.921 339.925 106.531 341.708 107.698 343.113C108.865 344.518 110.506 345.447 112.312 345.723C114.118 345.999 115.962 345.603 117.496 344.611C119.03 343.619 120.146 342.099 120.633 340.34Z"
            fill="#FFB6B6"
          />
          <path
            d="M104.275 331.04C103.832 330.892 103.425 330.653 103.081 330.339C102.737 330.024 102.462 329.641 102.276 329.213L89.3012 297.496C85.9926 289.892 85.5603 281.345 88.0847 273.446L96.3986 247.42C96.525 246.02 96.9263 244.659 97.5794 243.415C98.2325 242.17 99.1247 241.066 100.205 240.166C101.285 239.267 102.532 238.589 103.875 238.171C105.218 237.753 106.63 237.604 108.03 237.732C109.431 237.86 110.792 238.263 112.036 238.917C113.281 239.572 114.384 240.465 115.283 241.545C116.182 242.626 116.859 243.873 117.275 245.216C117.692 246.558 117.839 247.969 117.709 249.369L106.683 291.279L114.068 324.194C114.184 324.867 114.09 325.558 113.8 326.176C113.509 326.793 113.036 327.306 112.444 327.646L106.97 330.772C106.383 331.107 105.708 331.255 105.034 331.196C104.776 331.174 104.521 331.122 104.275 331.04Z"
            fill="#E4E4E4"
          />
          <path
            d="M150.423 493.55H159.471L163.777 458.667H150.421L150.423 493.55Z"
            fill="#FFB6B6"
          />
          <path
            d="M148.114 490.597L165.935 490.596H165.936C168.947 490.596 171.836 491.792 173.966 493.921C176.095 496.049 177.292 498.936 177.292 501.946V502.315L148.115 502.316L148.114 490.597Z"
            fill="#2F2E41"
          />
          <path
            d="M99.4944 493.55H108.543L112.848 458.667H99.4922L99.4944 493.55Z"
            fill="#FFB6B6"
          />
          <path
            d="M97.1855 490.597L115.006 490.596H115.007C118.019 490.596 120.907 491.792 123.037 493.921C125.167 496.049 126.363 498.936 126.363 501.946V502.315L97.1861 502.316L97.1855 490.597Z"
            fill="#2F2E41"
          />
          <path
            d="M155.871 317.607L102.804 312.941C102.804 312.941 97.5616 346.375 98.2997 359.653C99.0377 372.931 99.7758 393.585 99.7758 393.585L93.8711 483.579L116.752 480.628L119.704 406.125L129.3 365.554L140.371 417.19L147.752 483.579L167.68 481.366L161.038 400.962C161.038 400.962 166.942 328.672 155.871 317.607Z"
            fill="#2F2E41"
          />
          <path
            d="M134.467 219.499C142.62 219.499 149.229 212.894 149.229 204.746C149.229 196.598 142.62 189.993 134.467 189.993C126.314 189.993 119.705 196.598 119.705 204.746C119.705 212.894 126.314 219.499 134.467 219.499Z"
            fill="#FFB8B8"
          />
          <path
            d="M150.632 237.688L140.089 229.3C140.089 229.3 128.672 220.495 121.974 227.005L118.967 231.302C118.967 231.302 101.918 237.688 101.918 245.802L102.729 290.314C102.729 290.314 96.8241 313.181 105.681 314.656L157.275 318.83L156.61 276.298L150.632 237.688Z"
            fill="#E4E4E4"
          />
          <path
            d="M123.026 217.655C123.026 217.655 107.526 206.59 115.645 193.313C121.277 184.101 132.949 184.83 139.569 186.141C141.353 186.47 143.03 187.228 144.455 188.348C145.881 189.469 147.012 190.92 147.752 192.575C148.859 195.157 148.859 197.739 144.43 197.739C135.573 197.739 140.002 204.377 140.002 204.377C140.002 204.377 134.097 205.853 135.573 211.016C137.049 216.18 123.026 217.655 123.026 217.655Z"
            fill="#2F2E41"
          />
          <path
            d="M203.89 315.427C203.615 314.378 203.113 313.402 202.42 312.568C201.727 311.733 200.859 311.061 199.878 310.598C198.896 310.136 197.825 309.893 196.74 309.889C195.655 309.884 194.582 310.118 193.597 310.573L175.298 291.589L172.293 304.953L190.131 320.932C191.005 322.535 192.437 323.762 194.156 324.38C195.875 324.999 197.761 324.966 199.457 324.287C201.153 323.609 202.541 322.332 203.358 320.699C204.175 319.066 204.364 317.19 203.89 315.427Z"
            fill="#FFB6B6"
          />
          <path
            d="M185.074 315.716C184.617 315.814 184.146 315.814 183.689 315.718C183.233 315.621 182.802 315.43 182.424 315.156L155.132 294.416C148.418 289.544 143.703 282.401 141.865 274.315L135.804 247.674C135.201 246.404 134.854 245.028 134.784 243.623C134.714 242.219 134.921 240.815 135.394 239.491C135.868 238.167 136.597 236.949 137.542 235.907C138.487 234.866 139.628 234.02 140.9 233.42C142.172 232.819 143.55 232.475 144.955 232.408C146.36 232.34 147.765 232.55 149.089 233.025C150.413 233.501 151.63 234.233 152.671 235.179C153.711 236.125 154.555 237.267 155.153 238.539L166.948 280.24L190.033 304.849C190.474 305.369 190.745 306.013 190.808 306.692C190.871 307.371 190.725 308.054 190.387 308.647L187.26 314.117C186.924 314.704 186.417 315.174 185.808 315.465C185.574 315.578 185.327 315.662 185.074 315.716Z"
            fill="#E4E4E4"
          />
          <path
            d="M559.958 22.2602H133.668C133.505 22.2596 133.348 22.1942 133.232 22.0783C133.117 21.9624 133.052 21.8055 133.052 21.6418C133.052 21.4782 133.117 21.3213 133.232 21.2054C133.348 21.0895 133.505 21.0241 133.668 21.0235H559.958C560.122 21.0241 560.279 21.0895 560.395 21.2054C560.51 21.3213 560.575 21.4782 560.575 21.6418C560.575 21.8055 560.51 21.9624 560.395 22.0783C560.279 22.1942 560.122 22.2596 559.958 22.2602Z"
            fill="#CACACA"
          />
          <path
            d="M147.595 13.6034C151.354 13.6034 154.401 10.5582 154.401 6.80172C154.401 3.04523 151.354 0 147.595 0C143.836 0 140.789 3.04523 140.789 6.80172C140.789 10.5582 143.836 13.6034 147.595 13.6034Z"
            fill="#3F3D56"
          />
          <path
            d="M171.106 13.6034C174.864 13.6034 177.911 10.5582 177.911 6.80172C177.911 3.04523 174.864 0 171.106 0C167.347 0 164.3 3.04523 164.3 6.80172C164.3 10.5582 167.347 13.6034 171.106 13.6034Z"
            fill="#3F3D56"
          />
          <path
            d="M194.616 13.6034C198.375 13.6034 201.422 10.5582 201.422 6.80172C201.422 3.04523 198.375 0 194.616 0C190.858 0 187.811 3.04523 187.811 6.80172C187.811 10.5582 190.858 13.6034 194.616 13.6034Z"
            fill="#3F3D56"
          />
          <path
            d="M545.872 4.16042H529.167C528.839 4.15929 528.526 4.0285 528.295 3.7967C528.063 3.5649 527.934 3.25099 527.934 2.92374C527.934 2.5965 528.063 2.28259 528.295 2.05079C528.526 1.819 528.839 1.6882 529.167 1.68707H545.872C546.199 1.6882 546.513 1.819 546.744 2.05079C546.975 2.28259 547.105 2.5965 547.105 2.92374C547.105 3.25099 546.975 3.5649 546.744 3.7967C546.513 4.0285 546.199 4.15929 545.872 4.16042Z"
            fill="#3F3D56"
          />
          <path
            d="M545.872 8.79793H529.167C528.839 8.7968 528.526 8.66601 528.295 8.43421C528.063 8.20241 527.934 7.88851 527.934 7.56126C527.934 7.23401 528.063 6.9201 528.295 6.68831C528.526 6.45651 528.839 6.32572 529.167 6.32458H545.872C546.199 6.32572 546.513 6.45651 546.744 6.68831C546.975 6.9201 547.105 7.23401 547.105 7.56126C547.105 7.88851 546.975 8.20241 546.744 8.43421C546.513 8.66601 546.199 8.7968 545.872 8.79793Z"
            fill="#3F3D56"
          />
          <path
            d="M545.872 13.4354H529.167C528.839 13.4343 528.526 13.3035 528.295 13.0717C528.063 12.8399 527.934 12.526 527.934 12.1988C527.934 11.8715 528.063 11.5576 528.295 11.3258C528.526 11.094 528.839 10.9632 529.167 10.9621H545.872C546.199 10.9632 546.513 11.094 546.744 11.3258C546.975 11.5576 547.105 11.8715 547.105 12.1988C547.105 12.526 546.975 12.8399 546.744 13.0717C546.513 13.3035 546.199 13.4343 545.872 13.4354Z"
            fill="#3F3D56"
          />
          <path
            d="M281.952 502.505H0.738093C0.542339 502.505 0.354601 502.427 0.216182 502.289C0.0777626 502.15 0 501.963 0 501.767C0 501.572 0.0777626 501.384 0.216182 501.246C0.354601 501.107 0.542339 501.03 0.738093 501.03H281.952C282.147 501.03 282.335 501.107 282.474 501.246C282.612 501.384 282.69 501.572 282.69 501.767C282.69 501.963 282.612 502.15 282.474 502.289C282.335 502.427 282.147 502.505 281.952 502.505Z"
            fill="#CACACA"
          />
          <path
            d="M431.472 119.474H256.339C255.364 119.495 254.414 119.157 253.671 118.524C252.928 117.891 252.444 117.007 252.31 116.041C252.255 115.508 252.313 114.969 252.479 114.46C252.645 113.95 252.917 113.481 253.276 113.083C253.635 112.685 254.074 112.367 254.564 112.149C255.054 111.931 255.584 111.818 256.121 111.818H431.2C432.191 111.792 433.158 112.134 433.912 112.777C434.667 113.421 435.157 114.32 435.287 115.303C435.335 115.833 435.271 116.366 435.101 116.87C434.931 117.373 434.658 117.836 434.299 118.229C433.94 118.621 433.503 118.934 433.017 119.149C432.53 119.363 432.004 119.474 431.472 119.474Z"
            fill="#6C63FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_404_113">
            <rect width="597" height="503" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default PapersSvg;
