import React from 'react';

function Birdie() {
    return (
        <>
            <svg id='birdie' width="54" height="57" viewBox="0 0 54 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_32_1003)">
                    <path d="M29.9055 8.78436C28.3829 2.62885 21.3808 -1.63498 15.2609 0.604302C12.3783 1.65907 9.90018 3.80317 8.57166 6.59122C7.87838 8.04617 7.48774 9.57862 7.43359 11.1926C7.40002 12.1932 7.74285 13.8059 7.07733 14.3987C5.07312 16.184 7.24605 19.0231 9.5097 18.5772C10.9246 18.2985 12.3396 18.0198 13.7545 17.7411L10.5899 15.3304C10.7249 22.1139 12.1697 28.9612 16.6756 34.2384C20.7878 39.0544 27.6381 41.6086 33.7797 39.4735L30.6151 37.0628C31.3399 43.0627 33.8326 48.9702 38.2474 53.2091C40.3686 55.2458 43.0929 56.8263 46.1069 56.8309C48.9301 56.8353 51.6259 55.4923 53.0998 53.0554C53.6396 52.1629 53.5278 50.739 52.7089 50.0258C46.07 44.2436 46.0442 34.565 43.7159 26.7108C41.2945 18.5425 36.0078 11.5444 28.7702 7.03723C26.031 5.33141 23.5179 9.65532 26.2466 11.3546C33.1646 15.6627 37.5441 22.4123 39.4899 30.2406C41.5231 38.4203 42.396 47.6586 49.1734 53.5613L48.7825 50.5318C48.1389 51.5958 46.7788 51.9654 45.6144 51.8192C43.5935 51.5655 41.939 49.957 40.6683 48.4972C37.9237 45.3443 36.1162 41.2109 35.6151 37.0628C35.4355 35.5765 34.1467 34.0624 32.4505 34.6521C27.7579 36.2835 22.952 34.1616 19.9644 30.4074C16.592 26.1696 15.6947 20.5954 15.5899 15.3304C15.5558 13.6151 14.0265 12.6043 12.4253 12.9197C11.0104 13.1984 9.59545 13.4771 8.18051 13.7558L10.6129 17.9342C12.8535 15.9383 12.1331 13.2775 12.4644 10.6726C12.7105 8.7376 13.9884 6.9427 15.637 5.92936C19.33 3.65936 24.0778 6.04519 25.0841 10.1135C25.8563 13.2355 30.6794 11.9131 29.9055 8.78436Z" fill="white" />
                    <path d="M19.2969 35.6657C19.1124 37.5842 18.9279 39.5028 18.7434 41.4213C18.6856 42.0227 19.064 42.7774 19.4757 43.1891C19.9093 43.6227 20.6207 43.9492 21.2434 43.9213C21.89 43.8923 22.5606 43.6799 23.0112 43.1891C23.4836 42.6744 23.6767 42.1157 23.7434 41.4213C23.9279 39.5028 24.1124 37.5842 24.2969 35.6657C24.3547 35.0643 23.9763 34.3096 23.5646 33.898C23.131 33.4643 22.4196 33.1378 21.7969 33.1657C21.1503 33.1947 20.4797 33.4071 20.0291 33.898C19.5567 34.4126 19.3636 34.9714 19.2969 35.6657Z" fill="white" />
                    <path d="M21.6929 36.779C21.5084 38.6975 21.3239 40.616 21.1394 42.5346C21.0816 43.136 21.46 43.8907 21.8716 44.3023C22.3053 44.7359 23.0167 45.0625 23.6394 45.0346C24.286 45.0056 24.9566 44.7932 25.4072 44.3023C25.8796 43.7877 26.0726 43.2289 26.1394 42.5346C26.3239 40.616 26.5084 38.6975 26.6929 36.779C26.7507 36.1775 26.3723 35.4229 25.9606 35.0112C25.527 34.5776 24.8156 34.2511 24.1929 34.279C23.5463 34.308 22.8757 34.5203 22.4251 35.0112C21.9526 35.5259 21.7596 36.0846 21.6929 36.779Z" fill="white" />
                    <path d="M16.6584 12.1211C19.8756 12.1211 19.8809 7.12109 16.6584 7.12109C13.4411 7.12109 13.4358 12.1211 16.6584 12.1211Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_32_1003">
                        <rect width="53.4313" height="56.831" fill="white" />
                    </clipPath>
                </defs>
            </svg>


        </>

    );
}

export default Birdie;
